import { NavigatorScreenParams } from '@react-navigation/native';
import { PathConfig } from 'src/routes/types';
import AppointmentStackParamsList, {
  APPOINTMENT_STACK_PATHS
} from '../AppointmentStackNavigator/ParamsList';
import HomeStackParamsList, { HOME_STACK_PATHS } from '../HomeStackNavigator/ParamsList';
import { Navigators } from '../navigators';
import PatientStackParamsList, { PET_STACK_PATHS } from '../PatientStackNavigator/ParamsList';
import TodoStackParamList, { TODO_STACK_PATHS } from '../ToDoStackNavigator/ParamsList';
import { Screens } from '../screens';
import { Patient } from 'src/interfaces';

type TabStackParamsList = {
  [Navigators.HOME_STACK]: NavigatorScreenParams<HomeStackParamsList>;
  [Navigators.APPOINTMENTS_STACK]: NavigatorScreenParams<AppointmentStackParamsList>;
  [Navigators.TODO_STACK]: NavigatorScreenParams<TodoStackParamList>;
  [Screens.REMINDERS]?: {
    id?: Patient['patientId'];
  };
  [Screens.PRESCRIPTIONS]?: {
    id?: Patient['patientId'];
  };
  [Navigators.PET_STACK]: NavigatorScreenParams<PatientStackParamsList>;
};

export const TAB_STACK_PATHS: PathConfig<TabStackParamsList> = {
  initialRouteName: Navigators.HOME_STACK,
  screens: {
    [Navigators.HOME_STACK]: HOME_STACK_PATHS,
    [Navigators.APPOINTMENTS_STACK]: APPOINTMENT_STACK_PATHS,
    [Navigators.TODO_STACK]: TODO_STACK_PATHS,
    [Screens.REMINDERS]: 'reminders',
    [Screens.PRESCRIPTIONS]: 'prescriptions',
    [Navigators.PET_STACK]: PET_STACK_PATHS
  }
};

export default TabStackParamsList;
