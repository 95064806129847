import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Discount: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M10.313 13.688A1.687 1.687 0 1 0 12 12a1.687 1.687 0 1 1 1.688-1.688M12 7.5v1.125m0 6.75V16.5m8.438-4.5a8.438 8.438 0 1 1-16.876 0 8.438 8.438 0 0 1 16.875 0Z'
    />
  </Svg>
);
export default Discount;
