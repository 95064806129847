import React, { useCallback, useRef, useState } from 'react';
import { PATIENT_STATUSES } from 'src/constants';
import styled from 'styled-components/native';
import PatientListContent from './components/PatientListContent';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { SliderElement, SLIDES, STEPS, TourGuide } from './tour';
import { TourGuideHandle } from 'src/module/TourGuide/model';
import { QueryKey, useQueries } from 'react-query';
import { QueryKeys } from 'src/api';
import SidebarPatientTypeSelector from './components/SidebarPatientTypeSelector';
import { LayoutAnimation } from 'react-native';
import FloatingPawPath from 'src/components/FloatingPawPath';
import { Patient } from 'src/interfaces';
import { useFocusEffect } from '@react-navigation/native';

const queries: QueryKey[] = [QueryKeys.PAGINATED_PATIENTS, QueryKeys.PATIENTS];

interface Props {
  petNavOverride?: (id: Patient['patientId']) => void;
}
const PatientListRoot: React.FC<Props> = ({ petNavOverride }) => {
  const { viewMode } = useAppTheme();

  const [selectedStatus, setSelectedStatus] = useState<PATIENT_STATUSES | undefined>(
    PATIENT_STATUSES.ACTIVE
  );

  const results = useQueries(queries.map((key) => ({ queryKey: [key] })));
  const isRefreshing = results.some((query) => query.isRefetching && !query.isStale);

  const providerRef = useRef<TourGuideHandle>(null);
  const [isOpen, setOpen] = useState(viewMode.isWeb);

  useFocusEffect(
    useCallback(() => {
      if (viewMode.isWeb) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, [viewMode.isWeb])
  );

  const toggleOpen = () => {
    if (!viewMode.isWeb) {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setOpen((prev) => !prev);
    }
  };
  return (
    <TourGuide.Provider ref={providerRef} disabled={isRefreshing}>
      <ResponsiveContainer>
        <SidebarPatientTypeSelector
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        <PatientListContent
          petNavOverride={petNavOverride}
          patientStatus={selectedStatus}
          toggleOpen={toggleOpen}
          selectedStatus={selectedStatus}
          filterIsOpen={isOpen}
        />
      </ResponsiveContainer>
      <SliderElement id={STEPS.DRAWER} slides={SLIDES} autoStart />
      <FloatingPawPath />
    </TourGuide.Provider>
  );
};

export default PatientListRoot;

const ResponsiveContainer = styled.View`
  flex-direction: ${({ theme }) => (theme.viewMode.isWeb ? 'row' : 'col')};
  flex: 1;
`;
