import React from 'react';
import { Body, HoverButton } from 'src/components';
import styled from 'styled-components/native';
import { Margin } from 'src/constants';
import { ChevronLeft } from 'react-native-feather';
import useGoBack from 'src/hooks/useGoBack';
import { Weight } from 'src/theme';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  title: string;
  rightIcon?: never;
  rightAction?: never;
}

interface PropsWithRightAction {
  title: string;
  rightIcon: typeof ChevronLeft;
  rightAction: () => void;
}

const InnerNavBar = ({ title, rightAction, rightIcon }: Props | PropsWithRightAction) => {
  const goBack = useGoBack();
  const { colors } = useAppTheme();
  return (
    <NavHeader>
      <BackButton onPress={goBack} icon={ChevronLeft} mode='contained' />
      <SemiBoldBody>{title}</SemiBoldBody>
      {rightIcon && rightAction && (
        <BackButton onPress={rightAction} icon={rightIcon} buttonColor={colors.text} />
      )}
    </NavHeader>
  );
};

export default InnerNavBar;

const NavHeader = styled.View`
  padding: ${Margin.Large}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${Margin.Medium}px;
  border-bottom-width: 0.5px;
  border-bottom-color: ${({ theme }) => theme.colors.disabled};
  background-color: ${({ theme }) => theme.colors.surface};
`;

const BackButton = styled(HoverButton).attrs({})`
  padding: ${Margin.Small}px;
  border-radius: ${Margin.ExtraLarge}px;
  flex-shrink: 0;
`;

const SemiBoldBody = styled(Body)`
  flex: 1;
  font-weight: ${Weight.MEDIUM};
`;
