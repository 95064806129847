import { useFocusEffect } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  getCalendarsAsync,
  getDefaultCalendarAsync,
  EntityTypes,
  Calendar,
  createEventAsync
} from 'expo-calendar';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { AsyncStorageKeys } from 'src/api';
import { AlertHandle, BottomButton, DropDown, TextInput } from 'src/components';
import Alert, { AlertOption } from 'src/components/Alert';
import { alertOptions as alertOptionsArray, IS_IOS } from 'src/constants';
import { useBoolean, usePractice, useStorage } from 'src/hooks';
import MainStackParamsList from 'src/routes/stacks/MainStack/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import { layout } from 'src/theme/globalStyles';
import useAppointment from '../AppointmentDetails/hooks/useAppointment';
import Details from './Details';
import { CalendarEntry, validationSchema } from './helpers';
import { simpleAddressFormatter } from 'src/utils';
import { ScrollView } from 'react-native';
import rateUs from 'src/utils/rateUs';

type Props = NativeStackScreenProps<MainStackParamsList, Screens.ADD_TO_CALENDAR>;

const AddToCalendar: React.FC<Props> = ({ route, navigation }) => {
  const { t, i18n } = useTranslation();
  const appointmentId = route.params?.id ?? '';
  const species = route.params?.species ?? '';

  const { data: appointment } = useAppointment(appointmentId);

  const [calendars, setCalendars] = useState<Calendar[]>();
  const [defaultCalendarId, setDefaultCalendarId] = useState<string>();
  const { value: isAddingEvent, toTrue: addingEvent, toFalse: doneAddingEvent } = useBoolean(false);
  const { data: practice } = usePractice();

  const [, setCalendarEntry] = useStorage<string>([
    AsyncStorageKeys.CALENDAR_APPOINTMENT,
    appointmentId
  ]);

  useFocusEffect(
    useCallback(() => {
      void getCalendarsAsync(EntityTypes.EVENT).then((calendars) => {
        setCalendars(calendars);
        if (!defaultCalendarId) setDefaultCalendarId(calendars[0].id);
      });

      if (IS_IOS) {
        void getDefaultCalendarAsync().then((calendar) => {
          setDefaultCalendarId(calendar.id);
        });
      }
    }, [defaultCalendarId])
  );

  const alertOptions = useMemo(
    () =>
      alertOptionsArray.map((option) => ({
        value: { relativeOffset: -option },
        label: t('todos:createTodo.humanizedBefore', {
          humanizedMoment: moment.duration(option, 'minutes').locale(i18n.language).humanize()
        })
      })),
    [t, i18n]
  );

  const { values, errors, handleSubmit, setFieldValue } = useFormik<CalendarEntry>({
    enableReinitialize: true,
    initialValues: {
      calendarId: defaultCalendarId ?? '',
      title: t('addToCalendar:appointment', { petName: appointment?.patient.name }),
      alerts: [{ relativeOffset: 0 }]
    },
    validationSchema,
    onSubmit: async (values) => {
      if (IS_IOS && values.alerts.length > 2) {
        confirmModalRef.current?.alert();
      } else if (appointment) {
        addingEvent();
        await createEventAsync(values.calendarId, {
          title: values.title,
          startDate: moment(appointment.startsAt).toDate(),
          endDate: moment(appointment.startsAt).add(appointment.duration, 'minutes').toDate(),
          notes: values.notes,
          location: simpleAddressFormatter(practice),
          alarms: values.alerts
        }).then((id) => {
          setCalendarEntry(id);
          Toast.show({
            type: 'success',
            text1: t('addToCalendar:confirmed')
          });

          void rateUs();
          navigation.goBack();
        });
      }
      doneAddingEvent();
    }
  });

  const confirmModalRef = useRef<AlertHandle>(null);
  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        label: t('common:OK')
      }
    ],
    [t]
  );

  const debouncedSubmit = useMemo(() => _.debounce(() => handleSubmit()), [handleSubmit]);

  if (!calendars) return null;
  return (
    <>
      <Alert
        ref={confirmModalRef}
        title={t('addToCalendar:dialogTitle')}
        body={t('addToCalendar:dialogMessage')}
        options={confirmOptions}
      />
      <ScrollView style={layout.flex1}>
        <Details appointmentId={appointmentId} species={species} />
        <TextInput
          label={t('addToCalendar:titleLabel')}
          onChangeText={async (title) => await setFieldValue('title', title)}
          value={values.title}
          error={!!errors.title}
          msg={errors.title}
        />
        <DropDown
          label={t('addToCalendar:calendar')}
          options={calendars.map((calendar) => ({
            label: calendar.title,
            value: calendar.id
          }))}
          value={values.calendarId}
          onChange={async (calendarId) => await setFieldValue('calendarId', calendarId)}
        />
        <DropDown
          multiSelect
          label={t('todos:createTodo.alertTimes')}
          options={alertOptions}
          value={values.alerts}
          onChange={async (alerts) => await setFieldValue('alerts', alerts)}
        />
        <TextInput
          label={t('todos:createTodo.notes')}
          value={values.notes ?? ''}
          onChangeText={async (notes) => await setFieldValue('notes', notes)}
        />
      </ScrollView>
      <BottomButton onPress={debouncedSubmit} disabled={!_.isEmpty(errors) || isAddingEvent}>
        {t('addToCalendar:save')}
      </BottomButton>
    </>
  );
};

export default AddToCalendar;
