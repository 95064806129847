import {
  AlarmClock,
  AlarmBell,
  Prescriptions,
  Paw,
  Calendar,
  Trophy
} from 'src/components/kit/ABIcons';
import { Navigators } from '../../navigators';
import { Screens } from '../../screens';
import { AlertBadge } from 'src/components';
import ClientAvatar from 'src/components/ClientAvatar';
import styled from 'styled-components/native';
import { Size } from 'src/constants';
import { Inbox } from 'react-native-feather';
import { useNavigation, NavigationProp, ParamListBase } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';

interface Props {
  todoAlert?: boolean;
  routeName: string;
  color: string;
  size: number;
}

const HeaderIcon: React.FC<Props> = ({ routeName, color, todoAlert, size }) => {
  const { navigate } = useNavigation<NavigationProp<ParamListBase>>();

  switch (routeName) {
    case Navigators.HOME_STACK:
      return (
        <TouchableOpacity onPress={() => navigate(Screens.PROFILE_ROOT)}>
          <StyledUserAvatar size={size} iconColor={color} />
        </TouchableOpacity>
      );
    case Navigators.APPOINTMENTS_STACK:
      return <Calendar width={size} height={size} color={color} />;
    case Navigators.PET_STACK:
      return <Paw width={size} height={size} color={color} />;
    case Navigators.TODO_STACK:
      return (
        <AlertBadge hasAlert={!!todoAlert}>
          <AlarmClock width={size} height={size} color={color} />
        </AlertBadge>
      );
    case Screens.PRESCRIPTIONS:
      return <Prescriptions width={size} height={size} color={color} />;
    case Screens.REMINDERS:
      return <AlarmBell width={size} height={size} color={color} />;
    case Navigators.LOYALTY_STACK:
      return <Trophy width={size} height={size} color={color} />;
    case Navigators.REQUEST_STACK:
      return <Inbox width={size} height={size} color={color} />;
    default:
      return null;
  }
};

export default HeaderIcon;

const StyledUserAvatar = styled(ClientAvatar)`
  background-color: transparent;
  border-radius: ${Size.XL}px;
`;
