import React from 'react';
import BasicInfo from './BasicInfo';
import AdditionalInfo from './AdditionalInfo';
import CommentsAndFiles from './CommentsAndFiles';
import { FormikData } from '../../helpers';
import { FormikErrors } from 'formik';
import { AssertsShape } from 'yup/lib/object';
import { FormStateHook } from '../../hooks/useFormState';
import { GappedContainer } from '../../style';

interface Props {
  values: FormikData;
  errors: FormikErrors<AssertsShape<any>>;
  setFieldValue: FormStateHook['setFieldValue'];
}

const EditInfo: React.FC<Props> = ({ values, errors, setFieldValue }) => {
  return (
    <GappedContainer>
      <BasicInfo values={values} setFieldValue={setFieldValue} errors={errors} />
      <AdditionalInfo values={values} setFieldValue={setFieldValue} errors={errors} />
      <CommentsAndFiles values={values} setFieldValue={setFieldValue} errors={errors} />
    </GappedContainer>
  );
};

export default EditInfo;
