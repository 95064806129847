import React, { lazy } from 'react';
import { createCustomNavigator } from './components/CustomNavigator';
import { Navigators } from '../navigators';
import { Screens } from '../screens';

import { useTranslation } from 'react-i18next';
import { useAbility } from 'src/hooks';

const Stack = createCustomNavigator();

const HomeStack = lazy(async () => import('../HomeStackNavigator'));
const AppointmentStack = lazy(async () => import('../AppointmentStackNavigator'));
const ToDoStack = lazy(async () => import('../ToDoStackNavigator'));
const Prescriptions = lazy(async () => import('src/scenes/Prescriptions'));
const Reminders = lazy(async () => import('src/scenes/Reminders'));
const PatientStack = lazy(async () => import('../PatientStackNavigator'));
const LoyaltyTab = lazy(async () => import('../LoyaltyTabNavigator'));
const RequestStack = lazy(async () => import('../RequestStackNavigator'));

const TabNavigator: React.FC = () => {
  const { t } = useTranslation('home');
  const { userCanViewLoyalty } = useAbility();

  return (
    <Stack.Navigator backBehavior='history' tabBarStyle={undefined}>
      <Stack.Screen name={Navigators.HOME_STACK} component={HomeStack} />
      <Stack.Screen name={Navigators.APPOINTMENTS_STACK} component={AppointmentStack} />
      <Stack.Screen name={Navigators.TODO_STACK} component={ToDoStack} />
      <Stack.Screen
        name={Screens.PRESCRIPTIONS}
        component={Prescriptions}
        options={{ title: t('prescriptions') }}
      />
      <Stack.Screen
        name={Screens.REMINDERS}
        component={Reminders}
        options={{ title: t('reminders') }}
      />
      <Stack.Screen name={Navigators.PET_STACK} component={PatientStack} />
      {userCanViewLoyalty && (
        <Stack.Screen name={Navigators.LOYALTY_STACK} component={LoyaltyTab} />
      )}
      <Stack.Screen name={Navigators.REQUEST_STACK} component={RequestStack} />
    </Stack.Navigator>
  );
};

export default TabNavigator;
