import React from 'react';
import { Caption, Column, DatePicker, Headline, Row, TextInput } from 'src/components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { View } from 'react-native';
import { Size } from 'src/constants';
import styled from 'styled-components/native';
import { GuideElement, STEPS } from 'src/scenes/PatientList/tour';
import { FormSection } from '../../style';
import { FormStateHook } from '../../hooks/useFormState';

interface Props {
  values: FormStateHook['values'];
  setFieldValue: FormStateHook['setFieldValue'];
  errors: FormStateHook['errors'];
}

const BasicInfo: React.FC<Props> = ({ values, setFieldValue, errors }) => {
  const { t } = useTranslation('patientProfile');

  return (
    <GuideElement body={t('tour.basicInfo')} id={STEPS.EDIT_BASIC_INFO} semiTransparentBg>
      <FormSection>
        <Headline>{t('basicInfo')}</Headline>
        <Caption>{t('theseChangesAfter')}</Caption>
        <View style={{ gap: Size.X3_S }}>
          <TextInput
            label={t('petID:name')}
            value={values.name ?? ''}
            onChangeText={async (name) => await setFieldValue('name', name)}
            error={!!errors.name}
            msg={errors.name && t(errors.name)}
          />
          <StyledRow>
            <Column>
              <TextInput
                label={t('petID:breed')}
                value={values.breed ?? ''}
                onChangeText={async (breed) => await setFieldValue('breed', breed)}
                error={!!errors.breed}
                msg={errors.breed && t(errors.breed)}
              />
            </Column>
            <Column>
              <TextInput
                label={t('petID:sex')}
                value={values.sex ?? ''}
                onChangeText={async (sex) => await setFieldValue('sex', sex)}
                error={!!errors.sex}
                msg={errors.sex && t(errors.sex)}
              />
            </Column>
          </StyledRow>
          <StyledRow>
            <Column>
              <DatePicker
                clearButtonMode='always'
                label={t('petID:dob')}
                value={values.birthdate ? values.birthdate : undefined}
                startYear={moment().subtract(100, 'years').year()}
                endYear={moment().year()}
                validRange={{
                  endDate: moment().startOf('day').toDate()
                }}
                onConfirm={(date) => {
                  if (!date) {
                    void setFieldValue('birthdate', undefined);
                  } else {
                    void setFieldValue('birthdate', moment(date).set(date).format('YYYY-MM-DD'));
                  }
                }}
              />
            </Column>
            <Column>
              <TextInput
                label={t('petID:weight')}
                value={values.weight ?? ''}
                onChangeText={async (weight) => await setFieldValue('weight', weight)}
                error={!!errors.weight}
                msg={errors.weight && t(errors.weight)}
              />
            </Column>
          </StyledRow>
          <StyledRow>
            <Column>
              <TextInput
                label={t('microchipTag')}
                value={values.microchipNumber ?? ''}
                onChangeText={async (microchipNumber) =>
                  await setFieldValue('microchipNumber', microchipNumber)
                }
                error={!!errors.microchipNumber}
                msg={errors.microchipNumber && t(errors.microchipNumber)}
              />
            </Column>
            <Column>
              <TextInput
                label={t('rabiesTag')}
                value={values.rabiesTag ?? ''}
                onChangeText={async (rabiesTag) => await setFieldValue('rabiesTag', rabiesTag)}
                error={!!errors.rabiesTag}
                msg={errors.rabiesTag && t(errors.rabiesTag)}
              />
            </Column>
          </StyledRow>
        </View>
      </FormSection>
    </GuideElement>
  );
};

export default BasicInfo;

export const StyledRow = styled(Row)`
  gap: ${Size.X3_S}px;
  align-items: flex-start;
`;
