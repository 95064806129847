import { useQuery, UseQueryOptions } from 'react-query';
import { getPatients, QueryKeys } from 'src/api';
import { Patient } from 'src/interfaces';

export const patientsOptions: UseQueryOptions<Patient[], unknown, Patient[], any> = {
  queryKey: [QueryKeys.PATIENTS],
  queryFn: getPatients
};

const usePatients = () => {
  return useQuery(patientsOptions);
};

export default usePatients;
