import { useQuery } from 'react-query';
import { getPatientAppointments, QueryKeys } from 'src/api';

const usePatientAppointments = (sourceId: number, patientId: string) => {
  return useQuery({
    queryKey: [QueryKeys.PATIENT_APPOINTMENTS, patientId, sourceId],
    queryFn: async () => await getPatientAppointments(sourceId, patientId)
  });
};

export default usePatientAppointments;
