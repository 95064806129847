import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, MapPin } from 'react-native-feather';
import { Body, Column, Row, Subheading } from 'src/components';
import AnimalIcon from 'src/components/Icons/Animal';
import { IconSize, Margin, Size } from 'src/constants';
import { Appointment } from 'src/interfaces';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { simpleAddressFormatter, toLocalDateTime } from 'src/utils';
import styled from 'styled-components/native';
import useAppointment from '../AppointmentDetails/hooks/useAppointment';
import { usePractice } from 'src/hooks';

interface DisplayData {
  icon: React.ReactNode;
  label: string;
  value: string;
}

interface Props {
  appointmentId: Appointment['appointmentId'];
  species?: string;
}

const Details: React.FC<Props> = ({ appointmentId, species }) => {
  const { t, i18n } = useTranslation();
  const { colors } = useAppTheme();
  const { data: practice } = usePractice();
  const { data: appointment } = useAppointment(appointmentId);
  const startTime = moment(appointment?.startsAt).locale(i18n.language);

  const displayData = useMemo(
    () =>
      [
        !!appointment?.patientDisplay && {
          icon: <AnimalIcon color={colors.text} size={IconSize.XS} species={species} />,
          value: appointment.patientDisplay,
          label: t('appointments:for')
        },
        {
          icon: <Calendar color={colors.text} width={IconSize.XS} height={IconSize.XS} />,
          value: toLocalDateTime(startTime, practice?.timeZone),
          label: t('appointments:when')
        },
        !!practice && {
          icon: <MapPin color={colors.text} width={IconSize.XS} height={IconSize.XS} />,
          value: simpleAddressFormatter(practice),
          label: t('common:address')
        }
      ].filter((d) => d) as DisplayData[],
    [appointment?.patientDisplay, colors.text, species, t, startTime, practice]
  );

  return (
    <Container>
      {displayData.map(({ value, icon, label }) => (
        <StyledRow key={label}>
          <IconContainer>{icon}</IconContainer>
          <Column>
            <Subheading>{label}</Subheading>
            <StyledBody>{value}</StyledBody>
          </Column>
        </StyledRow>
      ))}
    </Container>
  );
};

export default Details;

const Container = styled.View`
  margin-horizontal: ${Margin.Large}px;
  margin-top: ${Margin.Large}px;
`;

const IconContainer = styled.View`
  width: ${IconSize.XS}px
  height: ${IconSize.XS}px
  align-items: center;
  justify-content: center;
  margin-right: ${Size.XS}px;
`;

const StyledRow = styled(Row)`
  align-items: flex-start;
  margin-bottom: ${Size.M}px;
`;

const StyledBody = styled(Body)`
  margin-top: ${Size.X3_S}px;
`;
