export enum Screens {
  ACCEPT_TERMS = 'accept-terms',
  ADD_TO_CALENDAR = 'add-to-calendar',
  ADD_TO_DO = 'add-todo',
  AFTER_HOURS = 'after-hours',
  APPOINTMENTS = 'appointments',
  APPOINTMENT_DETAILS = 'appointment-details',
  APPOINTMENT_REQUEST = 'appointment-request',
  ARTICLE = 'article',
  ARTICLES_HUB = 'articles-hub',
  BIOMETRIC_LOGIN_AUTH = 'biometric-login-auth',
  BIOMETRIC_LOGIN_OPT_IN = 'biometric-login-opt-in',
  CONTACT_US = 'contact-us',
  EMAIL_CONFIRMATION = 'email-confirmation',
  HELP_CENTER = 'help-center',
  HOME = 'home',
  LANDING = 'landing',
  LOYALTY_ACTIVITY = 'loyalty-activity',
  LOYALTY_REWARD = 'loyalty-reward',
  LOYALTY_REWARDS = 'loyalty-rewards',
  ONLINE_BOOKING = 'online-booking',
  PATIENT_ID = 'patient-id',
  PATIENT_LIST = 'my-pets',
  PATIENT_PROFILE = 'patient-profile',
  PATIENT_PROFILE_UPDATE = 'patient-profile-update',
  PET_PAGE_DISABLED = 'no-access',
  PRACTICE_CLIENT_SEARCH = 'practice-client-search',
  PRESCRIPTIONS = 'prescriptions',
  PROFILE_APPSETTINGS = 'app-settings',
  PROFILE_CLIENT_PRACTICE_CONFIGURATION = 'communication-settings',
  PROFILE_INFORMATION_UPDATE = 'information-update-request',
  PROFILE_ROOT = 'profile',
  PROFILE_USERINFO = 'user-info',
  PUSH_NOTIFICATIONS_OPT_IN = 'push-notifications-opt-in',
  REMINDERS = 'reminders',
  RESET_PASSWORD_REQUIRED = 'reset-password',
  REQUESTS_INFORMATION_UPDATE = 'profile-information-update',
  REQUESTS_PATIENT_SELECTOR = 'request-patient-selector',
  REQUESTS_PRODUCT = 'requests-product',
  SANDBOX = 'sandbox',
  SELECT_PRACTICE = 'select-practice',
  TO_DOS = 'to-dos',
  TOKEN_PASSWORD_RESET = 'token-password-reset',
  TRANSACTION_DETAILS = 'transaction-details',
  REQUEST = 'request',
  REQUEST_DETAILS = 'request-details'
}
