import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AlertCircle: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      fill={props.color}
      d='M12 16.128a.75.75 0 1 0 0-1.5v1.5Zm.281-.472-.75-.002v.01l.75-.008Zm-.283-1.031a.75.75 0 0 0 .004 1.5l-.004-1.5Zm-.748-1.5a.75.75 0 0 0 1.5 0h-1.5Zm1.5-6.188a.75.75 0 0 0-1.5 0h1.5ZM3.562 12h-.75.75Zm16.875 0h.75-.75ZM12 3.562v-.75.75Zm0 11.066c-.204 0-.404.06-.574.175l.835 1.246a.468.468 0 0 1-.261.079v-1.5Zm-.574.175c-.17.113-.302.275-.38.464l1.388.57a.469.469 0 0 1-.173.212l-.835-1.246Zm-.38.464c-.077.19-.097.397-.057.598l1.47-.3a.47.47 0 0 1-.025.273l-1.388-.57Zm-.057.598c.041.2.14.384.286.527l1.055-1.066a.47.47 0 0 1 .13.24l-1.47.298Zm.286.527c.145.144.33.242.53.28l.283-1.473a.47.47 0 0 1 .242.127l-1.055 1.066Zm.53.28c.201.038.409.017.597-.063l-.585-1.381a.47.47 0 0 1 .271-.03l-.282 1.474Zm.597-.063a1.03 1.03 0 0 0 .46-.385l-1.254-.822a.469.469 0 0 1 .21-.174l.584 1.38Zm.46-.385c.113-.17.171-.371.17-.576l-1.5.016a.468.468 0 0 1 .076-.261l1.255.821Zm.17-.566c0-.135-.027-.27-.079-.396l-1.386.573a.47.47 0 0 1-.036-.18l1.5.003Zm-.079-.396a1.03 1.03 0 0 0-.224-.335l-1.06 1.06a.47.47 0 0 1-.102-.152l1.386-.573Zm-.224-.335a1.03 1.03 0 0 0-.335-.224l-.572 1.387a.472.472 0 0 1-.153-.102l1.06-1.061Zm-.335-.224a1.03 1.03 0 0 0-.396-.078l.004 1.5a.469.469 0 0 1-.18-.035l.572-1.387Zm.356-1.578V6.937h-1.5v6.188h1.5ZM2.812 12c0 2.437.968 4.774 2.691 6.497l1.061-1.061A7.687 7.687 0 0 1 4.312 12h-1.5Zm2.691 6.497A9.187 9.187 0 0 0 12 21.187v-1.5a7.687 7.687 0 0 1-5.436-2.251l-1.06 1.06ZM12 21.187a9.187 9.187 0 0 0 6.497-2.69l-1.061-1.061A7.687 7.687 0 0 1 12 19.688v1.5Zm6.497-2.69A9.187 9.187 0 0 0 21.187 12h-1.5c0 2.039-.81 3.994-2.251 5.436l1.06 1.06ZM21.187 12a9.187 9.187 0 0 0-2.69-6.497l-1.061 1.061A7.687 7.687 0 0 1 19.688 12h1.5Zm-2.69-6.497A9.187 9.187 0 0 0 12 2.813v1.5c2.039 0 3.994.81 5.436 2.251l1.06-1.06ZM12 2.813a9.187 9.187 0 0 0-6.497 2.69l1.061 1.061A7.687 7.687 0 0 1 12 4.312v-1.5Zm-6.497 2.69A9.187 9.187 0 0 0 2.813 12h1.5c0-2.039.81-3.994 2.251-5.436l-1.06-1.06Z'
    />
  </Svg>
);

export default AlertCircle;
