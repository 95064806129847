import React, { useEffect } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import PatientUpdateStackParamsList from 'src/routes/stacks/PatientUpdateStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import PatientListRoot from '../PatientList';
import { Patient } from 'src/interfaces';
import { ChevronLeft } from 'react-native-feather';
import { HoverButton } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';

const PatientSelector: React.FC<
  NativeStackScreenProps<PatientUpdateStackParamsList, Screens.REQUESTS_PATIENT_SELECTOR>
> = ({ route, navigation }) => {
  const { colors } = useAppTheme();
  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <HoverButton onPress={navigation.pop} icon={ChevronLeft} buttonColor={colors.text} />
      )
    });
  }, [colors.text, navigation]);
  if (route?.params?.dismiss) {
    // Navigate to requests home
  }

  const petNavOverride = (id: Patient['patientId']) => {
    navigation.navigate(Screens.PATIENT_PROFILE_UPDATE, { id });
  };

  return <PatientListRoot petNavOverride={petNavOverride} />;
};

export default PatientSelector;
