import { FAB } from 'react-native-paper';
import { Margin, Size } from 'src/constants';
import styled from 'styled-components/native';

export const StyledFAB = styled(FAB).attrs(({ theme }) => ({
  color: theme.colors.onPrimary,
  size: 'small'
}))`
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const FormSection = styled.View`
  gap: ${Size.S}px;
`;

export const GappedContainer = styled.View`
  gap: ${Margin.ExtraLarge}px;
  margin-horizontal: ${({ theme }) => (theme.viewMode.isWeb ? Margin.ExtraLarge : Margin.Medium)}px;
  margin-vertical: ${Margin.ExtraLarge}px;
`;
