import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ArrowRight: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='m7.125 3.563 8.04 8.04a.563.563 0 0 1 0 .795l-8.04 8.04'
    />
  </Svg>
);
export default ArrowRight;
