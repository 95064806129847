import { PathConfig } from 'src/routes/types';
import { Screens } from '../screens';
import { Patient } from 'src/interfaces';

export type PatientUpdateStackParamsList = {
  [Screens.REQUESTS_PATIENT_SELECTOR]?: { dismiss?: boolean };
  [Screens.PATIENT_PROFILE_UPDATE]?: {
    id: Patient['patientId'];
    exitOnBack?: boolean;
  };
};

export const PET_UPDATE_STACK_PATHS: PathConfig<PatientUpdateStackParamsList> = {
  initialRouteName: Screens.REQUESTS_PATIENT_SELECTOR,
  path: 'update-pet',
  screens: {
    [Screens.REQUESTS_PATIENT_SELECTOR]: 'select',
    [Screens.PATIENT_PROFILE_UPDATE]: 'edit'
  }
};

export default PatientUpdateStackParamsList;
