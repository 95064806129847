import { t } from 'i18next';
import { Patient, RequestSetting } from 'src/interfaces/api';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import Styles from './styles';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { NavigationProp } from '@react-navigation/native';
import { navigationRef } from 'src/utils/navigationRef';

interface ActionItems {
  icon: string;
  label: string;
  style: StyleProp<ViewStyle>;
  labelStyle: StyleProp<TextStyle>;
  containerStyle: StyleProp<ViewStyle>;
  onPress: Omit<NavigationProp<ReactNavigation.RootParamList>, 'getState'>['navigate'];
}

export const requestActions = (
  requestSettings: RequestSetting | undefined,
  requestAppointment: (patientId?: Patient['patientId']) => void,
  style: ReturnType<typeof Styles>,
  color: string
): ActionItems[] => {
  if (!requestSettings) return [];
  const baseAction = {
    style: style.actionStyle,
    labelStyle: style.labelStyle,
    containerStyle: style.containerStyle,
    color
  };
  const actions: ActionItems[] = [
    {
      ...baseAction,
      icon: 'alarm',
      label: t('requests:wizard.addTodo'),
      // @ts-expect-error typing for navigate func args on deeply nested screens is broken, but this works
      onPress: () => navigationRef.navigate(Screens.ADD_TO_DO)
    },
    {
      ...baseAction,
      icon: 'human',
      label: t('requests:wizard.updateMyInfo'),
      onPress: () =>
        navigationRef.navigate(Navigators.MAIN_STACK, {
          screen: Screens.PROFILE_INFORMATION_UPDATE
        })
    },
    {
      ...baseAction,
      icon: 'paw',
      label: t('requests:wizard.updatePetInfo'),
      onPress: () =>
        navigationRef.navigate(Navigators.MAIN_STACK, {
          screen: Navigators.PATIENT_UPDATE_STACK_NAVIGATOR
        })
    }
  ];

  if (!requestSettings?.appointmentRequestsDisabled) {
    actions.push({
      ...baseAction,
      icon: 'calendar',
      label: t('requests:wizard.newAppointment'),
      onPress: () => requestAppointment()
    });
  }

  if (!requestSettings?.refillRequestsDisabled) {
    actions.push({
      ...baseAction,
      icon: 'package',
      label: t('requests:wizard.productRequest'),
      onPress: () =>
        navigationRef.navigate(Navigators.MAIN_STACK, { screen: Screens.REQUESTS_PRODUCT })
    });
  }

  return actions;
};
