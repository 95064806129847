import React, { PropsWithChildren, useEffect, useImperativeHandle } from 'react';
import { Margin, MOBILE_WIDTH_MAX, Size } from 'src/constants';
import { useBoolean } from 'src/hooks';
import { ForwardRefFC } from 'src/types';
import styled from 'styled-components/native';
import { StyledModal } from './common';
import { HoverButton } from './kit';
import { Body, Subheading } from './Text';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Card } from 'react-native-paper';
import { InteractionManager } from 'react-native';

const MAX_ROW_SIZE = 2;
const MAX_CONTENT_WIDTH = 300;
export interface AlertOption {
  action?: () => void;
  label: string;
  type?: 'destructive' | 'confirmation' | 'neutral';
}

interface AlertProps extends PropsWithChildren {
  title: string;
  body?: React.ReactNode;
  options?: AlertOption[];
  dismissOnBackdropPress?: boolean;
}

export interface AlertHandle {
  alert: () => void;
}

const defaultOption: AlertOption = {
  label: 'Ok'
};

const Alert: ForwardRefFC<AlertHandle, AlertProps> = React.forwardRef<AlertHandle, AlertProps>(
  ({ options = [defaultOption], title, dismissOnBackdropPress, body, children }, ref) => {
    const { value: nativeIsVisible, setValue } = useBoolean();
    const { value: isVisible, toFalse: hide, toTrue: alert } = useBoolean();

    useEffect(() => {
      void InteractionManager.runAfterInteractions(() => {
        setValue(isVisible);
      });
    }, [isVisible, setValue]);

    useImperativeHandle(ref, () => ({ alert }), [alert]);

    const { colors } = useAppTheme();

    return (
      <StyledModal
        isVisible={nativeIsVisible}
        onDismiss={hide}
        onBackdropPress={dismissOnBackdropPress ? hide : undefined}
        onBackButtonPress={dismissOnBackdropPress ? hide : undefined}
        avoidKeyboard
      >
        <StyledCard style={{ maxWidth: MAX_CONTENT_WIDTH }}>
          <Content>
            <Subheading color={colors.onSurface} textAlign='center'>
              {title}
            </Subheading>
            {!!body && (
              <BodyContent>
                <Body color={colors.onSurface} textAlign='center'>
                  {body}
                </Body>
              </BodyContent>
            )}
            {children}
          </Content>
          <ButtonsContainer isColumn={options.length > MAX_ROW_SIZE}>
            {options.map(({ action, label, type = 'confirmation' }, i) => {
              return (
                <AlertButton
                  onPress={() => {
                    hide();
                    action?.();
                  }}
                  isColumn={options.length > MAX_ROW_SIZE}
                  isFirst={i === 0}
                  isLast={i === options.length - 1}
                  key={label}
                  buttonColor={type === 'destructive' ? colors.error : colors.primary}
                  mode={type === 'neutral' ? 'outlined' : 'contained'}
                  labelStyle={{ color: type === 'neutral' ? colors.onSurface : colors.onPrimary }}
                >
                  {label}
                </AlertButton>
              );
            })}
          </ButtonsContainer>
        </StyledCard>
      </StyledModal>
    );
  }
);

export default Alert;

const BodyContent = styled.View`
  margin: ${Margin.Medium}px;
`;

const Content = styled.ScrollView`
  padding: ${Margin.Large}px;
  max-width: ${MAX_CONTENT_WIDTH}px;
`;

const ButtonsContainer = styled.View<{ isColumn: boolean }>`
  flex-grow: 1;
  justify-content: center;
  align-items: stretch;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
`;

export const StyledCard = styled(Card)`
  width: 100%;
  padding-top: ${Size.X2_S}px;
  align-self: center;
  max-width: ${({ theme }) => (theme.viewMode.isMobile ? 'auto' : `${MOBILE_WIDTH_MAX}px`)};
`;

const AlertButton = styled(HoverButton)<{ isFirst?: boolean; isLast?: boolean; isColumn: boolean }>`
  ${({ isColumn }) => (isColumn ? 'flex-grow: 1' : 'flex: 1')};
  padding: ${Margin.Large}px;
  border-radius: 0px;
  border-width: 0px;
  border-top-width: 0.5px;
  border-bottom-left-radius: ${({ theme, isFirst, isLast, isColumn }) =>
    isFirst || (isLast && isColumn) ? `${theme.roundness}px` : '0px'};
  border-bottom-right-radius: ${({ theme, isLast }) => (isLast ? `${theme.roundness}px` : '0px')};
`;
