import { useNavigation, NavigationProp, ParamListBase } from '@react-navigation/native';
import React from 'react';
import { useUser } from 'src/providers/ClientProvider';
import { Screens } from 'src/routes/stacks/screens';
import { Headline, HoverButton, Row } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Size } from 'src/constants';
import styled from 'styled-components/native';
import { useAbility, useAvailablePractices, usePractice } from 'src/hooks';
import { Navigators } from 'src/routes/stacks/navigators';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import ClientAvatar from 'src/components/ClientAvatar';
import Hospital from 'src/components/Icons/Hospital';
import { Inbox } from 'react-native-feather';
import { Trophy } from 'src/components/kit/ABIcons';
import { GuideElement, STEPS } from 'src/scenes/Home/tour';
import { QueryKey, useQueries } from 'react-query';
import { QueryKeys } from 'src/api';
import { PADDING_TWENTY } from '../helper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Weight } from 'src/theme';
import { fontStyles } from 'src/theme/globalStyles';

const MAX_NAME_WIDTH = 200;
const queries: QueryKey[] = [
  QueryKeys.CHECK_EMAIL,
  QueryKeys.ANNOUNCEMENTS,
  QueryKeys.APPOINTMENTS,
  QueryKeys.PRESCRIPTIONS,
  QueryKeys.REMINDERS
];

const DesktopHeader: React.FC = () => {
  const { navigate } = useNavigation<NavigationProp<ParamListBase>>();
  const { user } = useUser();
  const { data: practice } = usePractice();
  const { t } = useTranslation('home');
  const { userCanViewLoyalty } = useAbility();
  const { colors } = useAppTheme();

  const results = useQueries(queries.map((key) => ({ queryKey: [key] })));
  const isFetched = results.every((query) => query.isFetched);
  const { data: availablePractices = [] } = useAvailablePractices(user.email, {
    select: (d) => d.filter((v) => v.userRegistered)
  });

  const hasMultiplePractices = !!availablePractices.length;
  const { top } = useSafeAreaInsets();

  return (
    <Container paddingTop={top}>
      <GapContainer style={{ flexShrink: 1 }}>
        <Hospital size={Size.XL} color={colors.onSurface} />
        <StyledHeadline>{practice?.name}</StyledHeadline>
        {hasMultiplePractices && (
          <GuideElement
            id={STEPS.SWITCH_PRACTICE}
            body={t('tour.switchPractice')}
            disabled={!hasMultiplePractices}
            autoStart={isFetched}
          >
            <ChangePracticeButton
              onPress={() => navigate(Navigators.MAIN_STACK, { screen: Screens.SELECT_PRACTICE })}
            >
              {t('home:changePractice')}
            </ChangePracticeButton>
          </GuideElement>
        )}
      </GapContainer>
      <GapContainer>
        <GapContainer>
          <ProfileButton onPress={() => navigate(Screens.PROFILE_ROOT)} icon={<StyledUserAvatar />}>
            {`${user.firstName} ${user.lastName}`}
          </ProfileButton>
        </GapContainer>
        <Row>
          {userCanViewLoyalty && (
            <HoverButton
              onPress={() =>
                navigate(Navigators.LOYALTY_STACK, { screen: Screens.LOYALTY_REWARDS })
              }
            >
              <Trophy height={Size.XL} width={Size.XL} color={colors.onSurface} />
            </HoverButton>
          )}
          <HoverButton
            onPress={() => navigate(Navigators.REQUEST_STACK, { screen: Screens.REQUEST })}
          >
            <Inbox height={Size.XL} width={Size.XL} color={colors.onSurface} />
          </HoverButton>
        </Row>
      </GapContainer>
    </Container>
  );
};

export default DesktopHeader;

const Container = styled(Row)<{ paddingTop: number }>`
  width: ${({ theme }) => theme.viewMode.windowWidth}px;
  padding-top: ${({ paddingTop }) => paddingTop}px;
  padding-left: ${Size.S}px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-bottom-width: ${Size.X4_S}px;
  border-bottom-color: ${({ theme }) => theme.colors.disabled};
  justify-content: space-between;
  gap: ${Size.XS}px;
`;

const GapContainer = styled(Row)`
  gap: ${Size.XS}px;
  align-items: center;
  align-self: stretch;
`;

const ProfileButton = styled(HoverButton).attrs(({ theme }) => ({
  buttonColor: theme.colors.onSurface,
  uppercase: false,
  labelStyle: {
    ...fontStyles.body,
    fontWeight: Weight.MEDIUM,
    maxWidth: MAX_NAME_WIDTH
  },
  numberOfLines: 1
}))`
  align-self: stretch;
`;

const ChangePracticeButton = styled(HoverButton).attrs({
  uppercase: false,
  labelStyle: { textDecorationLine: 'underline' }
})`
  align-self: stretch;
`;

const StyledUserAvatar = styled(ClientAvatar).attrs(({ theme: { colors } }) => ({
  size: Size.XL,
  iconSize: PADDING_TWENTY,
  iconColor: colors.onPrimary
}))`
  border-radius: ${Size.XL / 2}px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.onPrimary};
`;

const StyledHeadline = styled(Headline).attrs(({ theme }) => ({
  color: theme.colors.onSurface,
  numberOfLines: 1
}))`
  flex-shrink: 1;
`;
