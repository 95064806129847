import { Language } from 'src/constants';
import AddToCalendarEN from './locales/en/addToCalendar.json';
import AddToCalendarES from './locales/es/addToCalendar.json';
import AddToCalendarFR from './locales/fr/addToCalendar.json';
import AfterHoursEN from './locales/en/afterHours.json';
import AfterHoursES from './locales/es/afterHours.json';
import AfterHoursFR from './locales/fr/afterHours.json';
import AppointmentsEN from './locales/en/appointments.json';
import AppointmentsES from './locales/es/appointments.json';
import AppointmentsFR from './locales/fr/appointments.json';
import AppErrorEN from './locales/en/appError.json';
import AppErrorES from './locales/es/appError.json';
import AppErrorFR from './locales/fr/appError.json';
import ArticleHubEN from './locales/en/articleHub.json';
import ArticleHubES from './locales/es/articleHub.json';
import ArticleHubFR from './locales/fr/articleHub.json';
import BiometricLoginEN from './locales/en/biometricLogin.json';
import BiometricLoginES from './locales/es/biometricLogin.json';
import BiometricLoginFR from './locales/fr/biometricLogin.json';
import ChangePasswordEN from './locales/en/changePassword.json';
import ChangePasswordES from './locales/es/changePassword.json';
import ChangePasswordFR from './locales/fr/changePassword.json';
import ChangePasswordModalEN from './locales/en/changePasswordModal.json';
import ChangePasswordModalES from './locales/es/changePasswordModal.json';
import ChangePasswordModalFR from './locales/fr/changePasswordModal.json';
import CommonEN from './locales/en/common.json';
import CommonES from './locales/es/common.json';
import CommonFR from './locales/fr/common.json';
import ContactAllyEN from './locales/en/contactAlly.json';
import ContactAllyES from './locales/es/contactAlly.json';
import ContactAllyFR from './locales/fr/contactAlly.json';
import ContactUsEN from './locales/en/contactUs.json';
import ContactUsES from './locales/es/contactUs.json';
import ContactUsFR from './locales/fr/contactUs.json';
import DiscardChangesEN from './locales/en/discardChanges.json';
import DiscardChangesES from './locales/es/discardChanges.json';
import DiscardChangesFR from './locales/fr/discardChanges.json';
import EmailConfirmationEN from './locales/en/emailConfirmation.json';
import EmailConfirmationES from './locales/es/emailConfirmation.json';
import EmailConfirmationFR from './locales/fr/emailConfirmation.json';
import EulaEN from './locales/en/eula.json';
import EulaES from './locales/es/eula.json';
import EulaFR from './locales/fr/eula.json';
import ForcedUpdateEN from './locales/en/forcedUpdate.json';
import ForcedUpdateES from './locales/es/forcedUpdate.json';
import ForcedUpdateFR from './locales/fr/forcedUpdate.json';
import GenericErrorEN from './locales/en/genericError.json';
import GenericErrorES from './locales/es/genericError.json';
import GenericErrorFR from './locales/fr/genericError.json';
import HelpCenterEN from './locales/en/helpCenter.json';
import HelpCenterES from './locales/es/helpCenter.json';
import HelpCenterFR from './locales/fr/helpCenter.json';
import HomeEN from './locales/en/home.json';
import HomeES from './locales/es/home.json';
import HomeFR from './locales/fr/home.json';
import ImpersonateEN from './locales/en/impersonate.json';
import ImpersonateES from './locales/es/impersonate.json';
import ImpersonateFR from './locales/fr/impersonate.json';
import InfoUpdateEN from './locales/en/informationUpdate.json';
import InfoUpdateES from './locales/es/informationUpdate.json';
import InfoUpdateFR from './locales/fr/informationUpdate.json';
import LoginEN from './locales/en/login.json';
import LoginES from './locales/es/login.json';
import LoginFR from './locales/fr/login.json';
import LoyaltyEN from './locales/en/loyalty.json';
import LoyaltyES from './locales/es/loyalty.json';
import LoyaltyFR from './locales/fr/loyalty.json';
import OnboardingErrorsEN from './locales/en/onboardingErrors.json';
import OnboardingErrorsES from './locales/es/onboardingErrors.json';
import OnboardingErrorsFR from './locales/fr/onboardingErrors.json';
import OnlineBookingEN from './locales/en/onlineBooking.json';
import OnlineBookingES from './locales/es/onlineBooking.json';
import OnlineBookingFR from './locales/fr/onlineBooking.json';
import PatientProfileEN from './locales/en/patientProfile.json';
import PatientProfileES from './locales/es/patientProfile.json';
import PatientProfileFR from './locales/fr/patientProfile.json';
import PermissionsEN from './locales/en/permissions.json';
import PermissionsES from './locales/es/permissions.json';
import PermissionsFR from './locales/fr/permissions.json';
import PetIDEN from './locales/en/petID.json';
import PetIDES from './locales/es/petID.json';
import PetListEN from './locales/en/petList.json';
import PetListES from './locales/es/petList.json';
import PetListFR from './locales/fr/petList.json';
import PetPageDisabledEN from './locales/en/petPageDisabled.json';
import PetPageDisabledES from './locales/es/petPageDisabled.json';
import PetPageDisabledFR from './locales/fr/petPageDisabled.json';
import PracticeClientSearchEN from './locales/en/practiceClientSearch.json';
import PracticeClientSearchES from './locales/es/practiceClientSearch.json';
import PracticeClientSearchFR from './locales/fr/practiceClientSearch.json';
import PrescriptionsEN from './locales/en/prescriptions.json';
import PrescriptionsES from './locales/es/prescriptions.json';
import PrescriptionsFR from './locales/fr/prescriptions.json';
import ProfileClientPracticeConfigEN from './locales/en/profileClientPracticeConfig.json';
import ProfileClientPracticeConfigES from './locales/es/profileClientPracticeConfig.json';
import ProfileClientPracticeConfigFR from './locales/fr/profileClientPracticeConfig.json';
import ProfileRootEN from './locales/en/profileRoot.json';
import ProfileRootES from './locales/es/profileRoot.json';
import ProfileRootFR from './locales/fr/profileRoot.json';
import PushNotificationsEN from './locales/en/pushNotifications.json';
import PushNotificationsES from './locales/es/pushNotifications.json';
import PushNotificationsFR from './locales/fr/pushNotifications.json';
import RemindersEN from './locales/en/reminders.json';
import RemindersES from './locales/es/reminders.json';
import RemindersFR from './locales/fr/reminders.json';
import RequestsEN from './locales/en/requests.json';
import RequestsES from './locales/es/requests.json';
import RequestsFR from './locales/fr/requests.json';
import SelectPracticeEN from './locales/en/selectPractice.json';
import SelectPracticeES from './locales/es/selectPractice.json';
import SelectPracticeFR from './locales/fr/selectPractice.json';
import SettingsEN from './locales/en/settings.json';
import SettingsES from './locales/es/settings.json';
import SettingsFR from './locales/fr/settings.json';
import TimeRefEN from './locales/en/timeReference.json';
import TimeRefES from './locales/es/timeReference.json';
import TimeRefFR from './locales/fr/timeReference.json';
import TodosEN from './locales/en/todos.json';
import TodosES from './locales/es/todos.json';
import TodosFR from './locales/fr/todos.json';
import TokenResetPasswordEN from './locales/en/tokenPasswordReset.json';
import TokenResetPasswordES from './locales/es/tokenPasswordReset.json';
import TokenResetPasswordFR from './locales/fr/tokenPasswordReset.json';
import UserInfoEN from './locales/en/userInfo.json';
import UserInfoES from './locales/es/userInfo.json';
import UserInfoFR from './locales/fr/userInfo.json';
import ValidationEN from './locales/en/validation.json';
import ValidationES from './locales/es/validation.json';
import ValidationFR from './locales/fr/validation.json';
import DeeplinkingEN from './locales/en/deeplinking.json';
import DeeplinkingES from './locales/es/deeplinking.json';
import DeeplinkingFR from './locales/fr/deeplinking.json';

export const defaultLanguage = Language.en;

export const languagesResources = {
  en: {
    addToCalendar: AddToCalendarEN,
    afterHours: AfterHoursEN,
    appError: AppErrorEN,
    appointments: AppointmentsEN,
    articleHub: ArticleHubEN,
    biometricLogin: BiometricLoginEN,
    changePassword: ChangePasswordEN,
    changePasswordModal: ChangePasswordModalEN,
    common: CommonEN,
    contactAlly: ContactAllyEN,
    contactUs: ContactUsEN,
    deeplinking: DeeplinkingEN,
    discardChanges: DiscardChangesEN,
    emailConfirmation: EmailConfirmationEN,
    eula: EulaEN,
    forcedUpdate: ForcedUpdateEN,
    genericError: GenericErrorEN,
    helpCenter: HelpCenterEN,
    home: HomeEN,
    impersonate: ImpersonateEN,
    informationUpdate: InfoUpdateEN,
    login: LoginEN,
    loyalty: LoyaltyEN,
    onboardingErrors: OnboardingErrorsEN,
    onlineBooking: OnlineBookingEN,
    patientProfile: PatientProfileEN,
    permissions: PermissionsEN,
    petID: PetIDEN,
    petList: PetListEN,
    petPageDisabled: PetPageDisabledEN,
    practiceClientSearch: PracticeClientSearchEN,
    prescriptions: PrescriptionsEN,
    profileClientPracticeConfig: ProfileClientPracticeConfigEN,
    profileRoot: ProfileRootEN,
    pushNotifications: PushNotificationsEN,
    reminders: RemindersEN,
    requests: RequestsEN,
    selectPractice: SelectPracticeEN,
    settings: SettingsEN,
    timeReference: TimeRefEN,
    todos: TodosEN,
    tokenPasswordReset: TokenResetPasswordEN,
    userInfo: UserInfoEN,
    validation: ValidationEN
  },
  es: {
    addToCalendar: AddToCalendarES,
    afterHours: AfterHoursES,
    appError: AppErrorES,
    appointments: AppointmentsES,
    articleHub: ArticleHubES,
    biometricLogin: BiometricLoginES,
    changePassword: ChangePasswordES,
    changePasswordModal: ChangePasswordModalES,
    common: CommonES,
    contactAlly: ContactAllyES,
    contactUs: ContactUsES,
    deeplinking: DeeplinkingES,
    discardChanges: DiscardChangesES,
    emailConfirmation: EmailConfirmationES,
    eula: EulaES,
    forcedUpdate: ForcedUpdateES,
    genericError: GenericErrorES,
    helpCenter: HelpCenterES,
    home: HomeES,
    impersonate: ImpersonateES,
    informationUpdate: InfoUpdateES,
    login: LoginES,
    loyalty: LoyaltyES,
    onboardingErrors: OnboardingErrorsES,
    onlineBooking: OnlineBookingES,
    patientProfile: PatientProfileES,
    permissions: PermissionsES,
    petID: PetIDES,
    petList: PetListES,
    petPageDisabled: PetPageDisabledES,
    practiceClientSearch: PracticeClientSearchES,
    prescriptions: PrescriptionsES,
    profileClientPracticeConfig: ProfileClientPracticeConfigES,
    profileRoot: ProfileRootES,
    pushNotifications: PushNotificationsES,
    reminders: RemindersES,
    requests: RequestsES,
    selectPractice: SelectPracticeES,
    settings: SettingsES,
    timeReference: TimeRefES,
    todos: TodosES,
    tokenPasswordReset: TokenResetPasswordES,
    userInfo: UserInfoES,
    validation: ValidationES
  },
  fr: {
    addToCalendar: AddToCalendarFR,
    afterHours: AfterHoursFR,
    appError: AppErrorFR,
    appointments: AppointmentsFR,
    articleHub: ArticleHubFR,
    biometricLogin: BiometricLoginFR,
    changePassword: ChangePasswordFR,
    changePasswordModal: ChangePasswordModalFR,
    common: CommonFR,
    contactAlly: ContactAllyFR,
    contactUs: ContactUsFR,
    deeplinking: DeeplinkingFR,
    discardChanges: DiscardChangesFR,
    emailConfirmation: EmailConfirmationFR,
    eula: EulaFR,
    forcedUpdate: ForcedUpdateFR,
    genericError: GenericErrorFR,
    helpCenter: HelpCenterFR,
    home: HomeFR,
    impersonate: ImpersonateFR,
    informationUpdate: InfoUpdateFR,
    login: LoginFR,
    loyalty: LoyaltyFR,
    onboardingErrors: OnboardingErrorsFR,
    onlineBooking: OnlineBookingFR,
    patientProfile: PatientProfileFR,
    permissions: PermissionsFR,
    petID: PetListFR,
    petList: PetListFR,
    petPageDisabled: PetPageDisabledFR,
    practiceClientSearch: PracticeClientSearchFR,
    prescriptions: PrescriptionsFR,
    profileClientPracticeConfig: ProfileClientPracticeConfigFR,
    profileRoot: ProfileRootFR,
    pushNotifications: PushNotificationsFR,
    reminders: RemindersFR,
    requests: RequestsFR,
    selectPractice: SelectPracticeFR,
    settings: SettingsFR,
    timeReference: TimeRefFR,
    todos: TodosFR,
    tokenPasswordReset: TokenResetPasswordFR,
    userInfo: UserInfoFR,
    validation: ValidationFR
  }
};
