import { Patient } from 'src/interfaces';
import Icon from 'react-native-vector-icons/Ionicons';
import { IconProps } from 'src/components/Icons';

interface Props extends Partial<IconProps> {
  sex: Patient['sex'];
}

const PatientGenderIndicator = ({ sex, size, color }: Props) => {
  switch (sex.toLowerCase()) {
    case 'female':
    case 'spayed':
    case 'f':
    case 'fs':
    case 'sf':
      return <Icon name='female' size={size} color={color} />;
    case 'male':
    case 'neutered':
    case 'castrated':
    case 'm':
    case 'mn':
    case 'nm':
      return <Icon name='male' size={size} color={color} />;
  }
};

export default PatientGenderIndicator;
