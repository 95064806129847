import React, { useMemo, useState } from 'react';
import { Plus } from 'react-native-feather';
import { FAB } from 'react-native-paper';
import { IconSize, Size } from 'src/constants';
import { useRequestSetting } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { palette } from 'src/theme';
import styles from './styles';
import { requestActions } from './helper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface Props {
  bottomTabHeight: number;
}

const MainMenuFab: React.FC<Props> = ({ bottomTabHeight }) => {
  const { colors } = useAppTheme();
  const { requestAppointment } = useRequestSetting();
  const [open, setOpen] = useState(false);

  const style = useMemo(() => {
    return styles(colors);
  }, [colors]);

  const { data: requestSettings } = useRequestSetting();

  const { bottom } = useSafeAreaInsets();
  const paddingBottom = bottomTabHeight + Math.max(bottom, Size.M);

  const actions = useMemo(
    () => requestActions(requestSettings, requestAppointment, style, colors.onPrimary),
    [colors.onPrimary, requestAppointment, requestSettings, style]
  );

  return (
    <FAB.Group
      color={colors.onPrimary}
      backdropColor={palette.BLACK_OPACITY_54}
      style={[style.overlayStyle, { paddingBottom }]}
      fabStyle={[style.fabStyle]}
      open={open}
      visible={true}
      icon={() => <Plus color={colors.onPrimary} height={IconSize.M} width={IconSize.M} />}
      actions={actions}
      onStateChange={() => setOpen((prev) => !prev)}
    />
  );
};

export default MainMenuFab;
