import React from 'react';
import Svg, { Path } from 'react-native-svg';
import IconProps from './IconProps';

const Filter: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 32 32' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.05 9.25a4.002 4.002 0 01-7.6 0h-4.2a1.25 1.25 0 110-2.5h4.2a4.002 4.002 0 017.6 0h9.2a1.25 1.25 0 110 2.5h-9.2zM15 8a1.75 1.75 0 11-3.5 0A1.75 1.75 0 0115 8zM15.852 24.25a4.002 4.002 0 01-7.602 0h-3a1.25 1.25 0 110-2.5h3a4.002 4.002 0 017.602 0H26.25a1.25 1.25 0 110 2.5H15.852zM13.8 23a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z'
        fill={color}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.648 16.75a4.002 4.002 0 007.602 0h2a1.25 1.25 0 100-2.5h-2a4.002 4.002 0 00-7.602 0H5.25a1.25 1.25 0 100 2.5h11.398zM18.7 15.5a1.75 1.75 0 103.5 0 1.75 1.75 0 00-3.5 0z'
        fill={color}
      />
    </Svg>
  );
};

export default Filter;
