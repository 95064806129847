import React from 'react';
import IconProps from './IconProps';
import Svg, { Path } from 'react-native-svg';

const Hospital: React.FC<IconProps> = ({ color, size }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 32 32' fill='none'>
      <Path
        d='M2 11.9795L16 3.97949L30 11.9795'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path d='M16 12.9795V20.9795' stroke={color} strokeWidth='3' strokeLinejoin='round' />
      <Path d='M20 16.9795L12 16.9795' stroke={color} strokeWidth='3' strokeLinejoin='round' />
      <Path
        d='M7 14.9795V21.9795C7 24.1886 8.79086 25.9795 11 25.9795H21C23.2091 25.9795 25 24.1886 25 21.9795V14.9795'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};

export default Hospital;
