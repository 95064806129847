import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Screens } from '../screens';
import { useTranslation } from 'react-i18next';
import PatientUpdateStackParamsList from './ParamsList';
import PatientProfileUpdate from 'src/scenes/PatientProfileUpdate';
import PatientSelector from 'src/scenes/PatientSelector';
import ExitProvider from 'src/providers/ExitProvider';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { fontStyles } from 'src/theme/globalStyles';
import { fonts, Weight } from 'src/theme';

const PatientUpdateStack = createStackNavigator<PatientUpdateStackParamsList>();

export const PatientUpdateStackNavigator: React.FC = () => {
  const { t } = useTranslation('requests');
  const { colors } = useAppTheme();

  return (
    <ExitProvider>
      <PatientUpdateStack.Navigator>
        <PatientUpdateStack.Group
          screenOptions={{
            headerStyle: {
              backgroundColor: colors.background,
              borderBottomWidth: 0.5,
              borderBottomColor: colors.disabled
            },
            headerTitleStyle: {
              ...fontStyles.body,
              fontWeight: Weight.MEDIUM,
              fontFamily: fonts.roboto.medium
            },
            headerTintColor: colors.text
          }}
        >
          <PatientUpdateStack.Screen
            name={Screens.REQUESTS_PATIENT_SELECTOR}
            component={PatientSelector}
            options={{
              title: t('requests:wizard.whichPet')
            }}
          />
          <PatientUpdateStack.Screen
            name={Screens.PATIENT_PROFILE_UPDATE}
            component={PatientProfileUpdate}
            options={{ title: t('informationUpdate:editing') }}
          />
        </PatientUpdateStack.Group>
      </PatientUpdateStack.Navigator>
    </ExitProvider>
  );
};
