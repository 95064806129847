import { useLayoutEffect, useMemo, useState } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';
import { DeviceType, getDeviceTypeAsync } from 'expo-device';
import {
  IS_WEB,
  MOBILE_WIDTH_MAX,
  MOBILE_WIDTH_SMALL,
  TABLET_WIDTH_PORTRAIT_MAX,
  TABLET_WIDTH_PORTRAIT_MIN
} from 'src/constants/general';

export interface ViewModeHook {
  mode: DeviceType | undefined;
  isMobile: boolean;
  isTablet: boolean;
  isWeb: boolean;
  isUnknown: boolean;
  isLandscape: boolean;
  windowWidth: number;
  maxContentWidth: number;
  horizontalInset: number;
  narrowHorizontalInset: number;
  narrowMaxContentWidth: number;
  windowHeight: number;
}

export const viewModeDefaults: ViewModeHook = {
  isMobile: false,
  isTablet: false,
  isWeb: false,
  isUnknown: false,
  isLandscape: false,
  windowWidth: Dimensions.get('window').width,
  maxContentWidth: Dimensions.get('window').width,
  horizontalInset: 0,
  narrowHorizontalInset: 0,
  narrowMaxContentWidth: 0,
  windowHeight: Dimensions.get('window').height,
  mode: undefined
};

const useViewMode = (): ViewModeHook => {
  const { width, height } = useWindowDimensions();

  const isLandscape = !IS_WEB && width > height;

  const [mode, setMode] = useState<DeviceType>(DeviceType.UNKNOWN);
  useLayoutEffect(() => {
    void getDeviceTypeAsync().then((deviceType) => {
      if (width < MOBILE_WIDTH_MAX) {
        setMode(DeviceType.PHONE);
      } else if (width < TABLET_WIDTH_PORTRAIT_MAX) {
        setMode(DeviceType.TABLET);
      } else {
        setMode(DeviceType.DESKTOP);
      }
    });
  }, [width]);

  const isMobile = mode === DeviceType.PHONE;
  const isTablet = mode === DeviceType.TABLET;
  const isWeb = mode === DeviceType.DESKTOP;
  const isUnknown = mode === DeviceType.UNKNOWN;

  const horizontalInset = Math.max((width - TABLET_WIDTH_PORTRAIT_MIN) / 2, 0);
  const narrowHorizontalInset = Math.max((width - MOBILE_WIDTH_SMALL) / 2, 0);
  const maxContentWidth = Math.min(TABLET_WIDTH_PORTRAIT_MIN, width);
  const narrowMaxContentWidth = isMobile ? width : Math.min(MOBILE_WIDTH_SMALL, width);

  return useMemo(
    () => ({
      mode,
      isMobile,
      isTablet,
      isWeb,
      isLandscape,
      horizontalInset,
      narrowHorizontalInset,
      windowWidth: width,
      maxContentWidth,
      narrowMaxContentWidth,
      windowHeight: height,
      isUnknown
    }),
    [
      horizontalInset,
      isLandscape,
      isMobile,
      isTablet,
      isWeb,
      maxContentWidth,
      narrowMaxContentWidth,
      narrowHorizontalInset,
      width,
      height,
      isUnknown,
      mode
    ]
  );
};

export default useViewMode;
