import React from 'react';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { Trans, useTranslation } from 'react-i18next';
import { Body, Row } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import Icon from 'react-native-vector-icons/FontAwesome';
import { IconSize, Margin, Size } from 'src/constants';
import styled from 'styled-components/native';
import { Navigators } from 'src/routes/stacks/navigators';
import { Screens } from 'src/routes/stacks/screens';
import { Weight } from 'src/theme';
import TabStackParamsList from 'src/routes/stacks/TabStackNavigator/ParamsList';
import { transparent } from 'src/utils';

interface Props {
  TextComponent?: typeof Body;
}
const HelpCenterSection: React.FC<Props> = ({ TextComponent = Body }) => {
  const { t } = useTranslation('helpCenter');
  const { colors } = useAppTheme();
  const { navigate } = useNavigation<NavigationProp<TabStackParamsList>>();

  return (
    <Container>
      <FilledIconContainer>
        <Icon name='support' color={colors.primary} size={IconSize.L} />
      </FilledIconContainer>
      <TextComponent>
        <Trans
          t={t}
          i18nKey={'needHelpCta'}
          components={{
            helpCenter: (
              <TextComponent
                fontWeight={Weight.MEDIUM}
                textDecorationLine='underline'
                onPress={() =>
                  navigate(Navigators.HOME_STACK, {
                    screen: Screens.HELP_CENTER
                  })
                }
              />
            ),
            strong: <TextComponent fontWeight={Weight.BOLD} />
          }}
        />
      </TextComponent>
    </Container>
  );
};

export default HelpCenterSection;

const Container = styled(Row)`
  gap: ${Size.X2_S}px;
  align-self: flex-start;
`;

export const FilledIconContainer = styled.View`
  margin-vertical: ${Margin.Large}px;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: ${({ theme }) => transparent(theme.colors.primary, 0.15)};
  border-radius: ${IconSize.L}px;
  padding: ${Size.X2_S}px;
`;
