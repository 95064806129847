/**
 * DebugFAB
 */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Animated,
  LayoutRectangle,
  PanResponder,
  ScaledSize,
  useWindowDimensions
} from 'react-native';
import { FAB, Portal, Menu } from 'react-native-paper';
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';
import { AsyncStorageKeys } from 'src/api';
import { IS_ANDROID, Size } from 'src/constants';
import { useBoolean } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { queryClient, storage } from 'src/utils';
import styled from 'styled-components/native';

import { useTourGuideMaster } from 'src/module/TourGuide/TourGuideMaster';
import { TOUR } from 'src/module/TourGuide/model';
import { secureStoreDeleteCredentials } from 'src/providers/AuthProvider/helper';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Screens } from 'src/routes/stacks/screens';
import RootStackParamList from 'src/routes/stacks/RootStackNavigator/ParamsList';
import {
  AppUserState,
  useImpersonation
} from 'src/providers/ImpersonationProvider/ImpersonationProvider';
/*
 * FAB component that moves when you drag it
 */
const DebugFAB: React.FC = () => {
  const { colors } = useAppTheme();
  const dimensions = useWindowDimensions();
  const edges = useSafeAreaInsets();
  const [layout, setLayout] = useState<LayoutRectangle>({ width: 0, height: 0, x: 0, y: 0 });

  const { navigate } = useNavigation<NavigationProp<RootStackParamList>>();
  const { height } = useWindowDimensions();

  const { verticalLimits, horizontalLimits } = useMemo(
    () => calcRange(edges, dimensions, layout),
    [edges, dimensions, layout]
  );

  const pan = useRef(new Animated.ValueXY({ x: 0, y: height - 2 * Size.X4_L })).current;
  useEffect(() => {
    pan.extractOffset();
  }, [pan]);
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderMove: Animated.event([null, { dx: pan.x, dy: pan.y }], {
        useNativeDriver: false
      }),
      onPanResponderRelease: (evt, gestureState) => {
        if (gestureState.dx <= Size.X3_S && gestureState.dy <= Size.X3_S) {
          openMenu();
        }
        pan.extractOffset();
      }
    })
  ).current;

  const positionX = pan.x.interpolate({
    inputRange: horizontalLimits,
    outputRange: horizontalLimits,
    extrapolate: 'clamp'
  });

  const positionY = pan.y.interpolate({
    inputRange: verticalLimits,
    outputRange: verticalLimits,
    extrapolate: 'clamp'
  });

  const { value: visible, toTrue: openMenu, toFalse: hideMenu } = useBoolean(false);

  const { toursInFocus, resetTour } = useTourGuideMaster();

  const { providerState, endAdminSession, findUser } = useImpersonation();

  // used to simulate a react-native javascript layer crash
  const [shouldCrash, setShouldCrash] = useState(false);

  if (shouldCrash) {
    throw new Error('Simulated crash');
  }

  return (
    <Portal>
      <StyledFABContainer
        pointerEvents={'box-none'}
        style={{ transform: [{ translateX: positionX }, { translateY: positionY }] }}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          setLayout(layout);
        }}
        {...panResponder.panHandlers}
      >
        <Menu
          onDismiss={hideMenu}
          visible={visible}
          anchor={
            <FAB
              icon='bug'
              style={{ backgroundColor: colors.surface }}
              size={'small'}
              onPress={IS_ANDROID ? undefined : openMenu}
            />
          }
          contentStyle={{
            backgroundColor: 'transparent',
            gap: Size.X2_S,
            shadowColor: 'transparent'
          }}
          style={{ marginVertical: layout.height }}
        >
          {DEBUG_MENU_ITEMS.map(({ label, onPress, icon }) => (
            <FAB
              key={label}
              label={label}
              icon={icon}
              onPress={() => {
                onPress();
                hideMenu();
              }}
              size='small'
              style={{ backgroundColor: colors.surface }}
            />
          ))}
          {toursInFocus.map((tour) => (
            <FAB
              key={tour.tourId.description}
              label={tour.tourId.description ?? 'test'}
              onPress={async () => {
                if (tour.tourId.description) {
                  resetTour(tour.tourId.description as TOUR);
                }
                hideMenu();
              }}
              onLongPress={() => {
                void tour.ref.current?.removeStorageVisited();
                hideMenu();
              }}
            />
          ))}
          <FAB
            label={'Whats New Version Bump'}
            icon={'newspaper-plus'}
            onPress={async () => {
              await storage.setTypedItem(AsyncStorageKeys.WHATS_NEW_VIEWED, 0);
              await queryClient.refetchQueries(AsyncStorageKeys.WHATS_NEW_VIEWED);
              hideMenu();
            }}
            size='small'
            style={{ backgroundColor: colors.surface }}
          />
          <FAB
            label={'Crash'}
            icon={'bug'}
            onPress={() => {
              setShouldCrash(true);
            }}
            size='small'
            style={{ backgroundColor: colors.surface }}
          />
          <FAB
            label={'Sandbox'}
            icon={'turtle'}
            onPress={async () => {
              navigate(Screens.SANDBOX);
              hideMenu();
            }}
            size='small'
            style={{ backgroundColor: colors.surface }}
          />
          {providerState?.appUserState === AppUserState.IMPERSONATE && (
            <>
              <FAB
                label={'Find A User'}
                icon={'account-search'}
                onPress={async () => await findUser()}
                size='small'
                style={{ backgroundColor: colors.error }}
              />
              <FAB
                label={'End Admin Session'}
                icon={'account-cog'}
                onPress={async () => await endAdminSession()}
                size='small'
                style={{ backgroundColor: colors.error }}
              />
            </>
          )}
        </Menu>
      </StyledFABContainer>
    </Portal>
  );
};

interface DebugOption {
  label: string;
  icon: string;
  onPress: () => void;
}

const DEBUG_MENU_ITEMS: DebugOption[] = [
  {
    icon: 'database-off',
    label: 'Reset Tutorials',
    onPress: async () => {
      const storageKeys = await storage.getAllKeys();
      const tutorialKeys = storageKeys.filter((key) => key.includes(AsyncStorageKeys.TUTORIALS));
      await storage.multiRemove(tutorialKeys);
      await queryClient.invalidateQueries([AsyncStorageKeys.TUTORIALS]);
    }
  },
  {
    icon: 'delete',
    label: 'Clear Secure Store',
    onPress: secureStoreDeleteCredentials
  },
  {
    icon: 'cookie',
    label: 'Clear Storage',
    onPress: async () => {
      await storage.clear();
      await queryClient.clear();
    }
  }
].filter((k: DebugOption | false): k is DebugOption => !!k);

export default DebugFAB;

const StyledFABContainer = styled(Animated.View)`
  position: absolute;
`;

const calcRange = (edges: EdgeInsets, dimensions: ScaledSize, layout: LayoutRectangle) => {
  const { width, height } = dimensions;
  const { top, bottom, left, right } = edges;

  if (width === 0 || height === 0) {
    return {
      verticalLimits: [0, 1],
      horizontalLimits: [0, 1]
    };
  }

  return {
    verticalLimits: [top + Size.S, height - bottom - layout.height - Size.S],
    horizontalLimits: [left + Size.S, width - right - layout.width - Size.S]
  };
};
