import React, { useEffect, useMemo, useRef } from 'react';
import { ScrollView } from 'react-native';
import { usePatient } from 'src/hooks';
import { layout } from 'src/theme/globalStyles';
import { ActivityIndicator } from 'react-native-paper';
import { QueryKeys } from 'src/api';
import { Alert, AlertHandle, BottomButton } from 'src/components';
import { QueryKey, useQueries } from 'react-query';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import { TourGuideHandle } from 'src/module/TourGuide/model';
import { ScrollRefProvider } from 'src/providers/ScrollableRefProvider';
import EditInfo from './components/EditInfo';
import ConfirmChanges from './components/ConfirmChanges';
import { useUser } from 'src/providers/ClientProvider';
import { FormState } from './model';
import { AlertOption } from 'src/components/Alert';
import styled from 'styled-components/native';
import useUploadPatientPhoto from 'src/hooks/react-query/useUploadPatientPhoto';
import useFormState from './hooks/useFormState';
import usePatientData from './hooks/usePatientData';
import { TourGuide } from '../PatientList/tour';
import { contactRequiredFields, isFieldDirty } from './helpers';
import _ from 'lodash';
import useGoBack from 'src/hooks/useGoBack';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Screens } from 'src/routes/stacks/screens';
import { useExitProvider } from 'src/providers/ExitProvider';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import FloatingPawPath, { PAW_PATH_SIZE } from 'src/components/FloatingPawPath';
import PatientProfileAvatar from './components/PatientProfileAvatar';
import PatientUpdateStackParamsList from 'src/routes/stacks/PatientUpdateStackNavigator/ParamsList';
import useNavControl from './components/useNavControl';

const queries: QueryKey[] = [QueryKeys.PATIENT, QueryKeys.PATIENTS];

function PatientProfileUpdate({
  route,
  navigation
}: NativeStackScreenProps<PatientUpdateStackParamsList, Screens.PATIENT_PROFILE_UPDATE>) {
  const { t } = useTranslation('patientProfile');
  const { user } = useUser();

  const patientId = route.params?.id;
  const exitOnBack = route.params?.exitOnBack;

  const exitProvider = useExitProvider();
  const { viewMode } = useAppTheme();
  const { data: patient, isFetched } = usePatient(patientId);

  const { isMutating } = useUploadPatientPhoto(patient);
  const confirmModalRef = useRef<AlertHandle>(null);

  const { submitAdditionalInfoUpdate, submitInfoUpdateRequest, uploadPhoto, deletePhoto } =
    usePatientData(patient);

  const goBack = useGoBack();

  const scrollRef = useRef<ScrollView>(null);

  const dismissModal = () => {
    exitProvider.closeModal();
  };

  const {
    state,
    formAdvance,
    formGoBack,
    values,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    resetForm,
    isSubmitting,
    initialValues,
    dirty
  } = useFormState(
    patient,
    user,
    confirmModalRef,
    goBack,
    submitAdditionalInfoUpdate,
    submitInfoUpdateRequest,
    uploadPhoto,
    deletePhoto,
    dismissModal,
    navigation.pop,
    exitOnBack
  );

  useNavControl({
    state: state.state,
    formAdvance,
    formGoBack,
    handleSubmit,
    hasContactMethod:
      !contactRequiredFields.some((field) => isFieldDirty(field, values, initialValues)) ||
      !!values.contactMethod,
    dirty,
    name: patient?.name
  });

  const confirmOptions: AlertOption[] = useMemo(
    () => [
      {
        action: () => {
          resetForm();
          formGoBack();
        },
        label: t('discardChanges:goBack'),
        type: 'neutral'
      },

      {
        label: t('discardChanges:keepEditing')
      }
    ],
    [formGoBack, resetForm, t]
  );

  useEffect(() => {
    if (!patientId || (isFetched && !patient)) {
      Toast.show({
        text1: t('petNotFound'),
        type: 'error'
      });
      const timeout = setTimeout(navigation.goBack, 500);
      return () => clearTimeout(timeout);
    }
  }, [patientId, isFetched, patient, navigation, t]);

  const bottomButtonPressed = () => {
    if (state.state === FormState.EDIT_INFO) {
      formAdvance();
    } else {
      handleSubmit();
    }
  };

  const results = useQueries(queries.map((key) => ({ queryKey: [key] })));
  const isRefreshing = results.some((query) => query.isRefetching && !query.isStale);

  const providerRef = useRef<TourGuideHandle>(null);

  const submitDisabled = useMemo(() => {
    const needsContactMethod =
      state.state === FormState.CONFIRM_CHANGES &&
      contactRequiredFields.some((field) => isFieldDirty(field, values, initialValues)) &&
      !values.contactMethod;
    const submitDisabled =
      _.isEqual(values, initialValues) || !isValid || !!errors.images || needsContactMethod;
    return submitDisabled;
  }, [errors.images, initialValues, isValid, state.state, values]);

  const patientIsMutating = isMutating || isSubmitting;

  useEffect(() => {
    scrollRef.current?.scrollTo(0);
  }, [state.state]);
  if (!patient) return <ActivityIndicator size='large' style={layout.flex1} />;

  return (
    <TourGuide.Provider ref={providerRef} disabled={isRefreshing}>
      <TourGuide.Consumer>
        {({ enabled }) => (
          <ResponsiveContainer>
            <ScrollRefProvider scrollRef={scrollRef}>
              <Container>
                <Content
                  bounces={false}
                  ref={scrollRef}
                  scrollEventThrottle={1}
                  scrollsToTop={!enabled}
                  onScrollEndDrag={() => {
                    providerRef.current?.updateScrollPositions();
                  }}
                >
                  {state.state === FormState.EDIT_INFO && (
                    <>
                      <PatientProfileAvatar
                        state={state}
                        patient={patient}
                        newPatientImageUri={values.newPatientImageUri}
                        setFieldValue={setFieldValue}
                        patientIsMutating={patientIsMutating}
                      />
                      <EditInfo values={values} errors={errors} setFieldValue={setFieldValue} />
                    </>
                  )}
                  {state.state === FormState.CONFIRM_CHANGES && (
                    <ConfirmChanges
                      initialValues={initialValues}
                      values={values}
                      setContactMethod={async (value) => setFieldValue('contactMethod', value)}
                    />
                  )}
                </Content>
                {viewMode.isMobile && (
                  <BottomButtonContainer>
                    <BottomButton disabled={submitDisabled} onPress={bottomButtonPressed}>
                      {t(state.buttonText)}
                    </BottomButton>
                  </BottomButtonContainer>
                )}
              </Container>
              <Alert
                ref={confirmModalRef}
                title={t('discardChanges:title')}
                options={confirmOptions}
              />
            </ScrollRefProvider>
            <FloatingPawPath />
          </ResponsiveContainer>
        )}
      </TourGuide.Consumer>
    </TourGuide.Provider>
  );
}

export default PatientProfileUpdate;

const ResponsiveContainer = styled.View`
  flex-direction: ${({ theme }) => (theme.viewMode.isWeb ? 'row' : 'column')};
  align-items: stretch;
  flex: 1;
`;

const Container = styled.View`
  flex: 1;
`;
const Content = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: {
    flexGrow: 1,
    // gap: Margin.ExtraLarge,
    maxWidth: theme.viewMode.isWeb ? theme.viewMode.maxContentWidth : '100%',
    paddingBottom: PAW_PATH_SIZE
  }
}))`
  flex-grow: 1;
`;

const BottomButtonContainer = styled.View`
  max-width: ${({ theme }) => theme.viewMode.maxContentWidth};
  border-top-width: 0.5px;
  border-top-color: ${({ theme }) => theme.colors.disabled};
`;
