import { useQuery } from 'react-query';
import { getPatientPrescriptions, QueryKeys } from 'src/api';

const usePatientPrescriptions = (sourceId: number, patientId: string) => {
  return useQuery({
    queryKey: [QueryKeys.PATIENT_PRESCRIPTIONS, sourceId, patientId],
    queryFn: async () => await getPatientPrescriptions(sourceId, patientId)
  });
};

export default usePatientPrescriptions;
