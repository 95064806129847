import React from 'react';
import PawPath from './PawPath';
import styled from 'styled-components/native';

export const PAW_PATH_SIZE = 200;

const FloatingPawPath: React.FC = () => {
  return (
    <PawPathContainer>
      <PawPath width={PAW_PATH_SIZE} height={PAW_PATH_SIZE} />
    </PawPathContainer>
  );
};

export default FloatingPawPath;

const PawPathContainer = styled.View`
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: -1;
`;
