import {
  AlarmBell,
  AlarmClock,
  Calendar,
  House,
  Paw,
  Prescriptions
} from 'src/components/kit/ABIcons';
import { Navigators } from '../navigators';
import { Size } from 'src/constants';
import { AlertBadge } from 'src/components';
import { Screens } from '../screens';

export const PADDING_TWENTY = 20;

export const tabIcon = (routeName: string, color: string, todoAlert?: boolean) => {
  switch (routeName) {
    case Navigators.HOME_STACK:
      return <House width={Size.M} height={Size.M} color={color} />;
    case Navigators.APPOINTMENTS_STACK:
      return <Calendar width={Size.M} height={Size.M} color={color} />;
    case Navigators.PET_STACK:
      return <Paw width={Size.M} height={Size.M} color={color} />;
    case Navigators.TODO_STACK:
      return (
        <AlertBadge hasAlert={!!todoAlert}>
          <AlarmClock width={Size.M} height={Size.M} color={color} />
        </AlertBadge>
      );
    case Screens.PRESCRIPTIONS:
      return <Prescriptions width={Size.M} height={Size.M} color={color} />;
    case Screens.REMINDERS:
      return <AlarmBell width={Size.M} height={Size.M} color={color} />;
    default:
      return null;
  }
};

export const getHeaderKey = (routeName: string) => {
  switch (routeName) {
    case Navigators.HOME_STACK:
      return 'routeTitles.home';
    case Navigators.APPOINTMENTS_STACK:
      return 'routeTitles.appointments';
    case Navigators.PET_STACK:
      return 'routeTitles.pets';
    case Navigators.TODO_STACK:
      return 'routeTitles.to-dos';
    case Screens.PRESCRIPTIONS:
      return 'routeTitles.prescriptions';
    case Screens.REMINDERS:
      return 'routeTitles.reminders';
    case Navigators.LOYALTY_STACK:
      return 'routeTitles.loyalty';
    case Navigators.REQUEST_STACK:
      return 'routeTitles.requests';
    default:
      return '';
  }
};
