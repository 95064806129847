import { StyleSheet } from 'react-native';
import { IS_ANDROID, IS_WEB, Size } from 'src/constants';
import { FontSize } from 'src/theme';
import { AppColors } from 'src/types/paper';

const BUTTON_WIDTH = 175;

const styles = (colors: AppColors) =>
  StyleSheet.create({
    overlayStyle: {
      zIndex: 2
    },
    actionStyle: {
      backgroundColor: colors.primary
    },
    labelStyle: {
      color: colors.onPrimary,
      fontSize: FontSize.BODY,
      alignSelf: 'center',
      paddingVertical: Size.X4_S
    },
    containerStyle: {
      backgroundColor: colors.primary,
      borderRadius: Size.X4_L,
      width: BUTTON_WIDTH
    },
    fabStyle: {
      backgroundColor: colors.primary,
      height: Size.X3_L,
      width: Size.X3_L,
      borderRadius: Size.X3_L,
      bottom: IS_WEB || IS_ANDROID ? 0 : -Size.XL,
      paddingTop: IS_WEB ? Size.X3_S : 0
    }
  });

export default styles;
