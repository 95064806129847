import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import {
  DefaultNavigatorOptions,
  ParamListBase,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
  createNavigatorFactory
} from '@react-navigation/native';
import { ActivityIndicator } from 'react-native-paper';
import { layout } from 'src/theme/globalStyles';
import TabContent from './TabContent';
import { useViewMode } from 'src/hooks';
import BottomTabNavbar from './BottomTabNavbar';
import DesktopHeader from './DesktopHeader';
import SideTabNavbar from './SideTabNavbar';
import MobileHeader from './MobileHeader';
import { FocusAwareStatusBar } from 'src/components';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import MainMenuFab from 'src/components/MainMenuFab';

type TabNavigationConfig = {
  tabBarStyle: StyleProp<ViewStyle>;
};

type TabNavigationOptions = {
  title?: string;
};

type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};

type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions &
  TabNavigationConfig;

const CustomNavigator: React.FC<Props> = ({ initialRouteName, children, screenOptions }) => {
  const { state, navigation, descriptors } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    TabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    children,
    screenOptions,
    initialRouteName
  });

  const { colors } = useAppTheme();
  const { isMobile, isUnknown } = useViewMode();
  const [bottomTabHeight, setBottomTabHeight] = React.useState(0);

  if (isUnknown) return <ActivityIndicator size='large' style={layout.flex1} />;

  if (isMobile)
    return (
      <>
        <FocusAwareStatusBar backgroundColor={colors.surface} />
        <MobileHeader state={state} />
        <TabContent state={state} descriptors={descriptors} />
        <BottomTabNavbar
          state={state}
          navigation={navigation}
          setBottomTabHeight={setBottomTabHeight}
        />
        <MainMenuFab bottomTabHeight={bottomTabHeight} />
      </>
    );

  return (
    <>
      <DesktopHeader />
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <SideTabNavbar state={state} navigation={navigation} />
        <TabContent state={state} descriptors={descriptors} />
      </View>
      <MainMenuFab bottomTabHeight={bottomTabHeight} />
    </>
  );
};
export const createCustomNavigator = createNavigatorFactory(CustomNavigator);
