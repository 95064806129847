import { TabNavigationState, ParamListBase } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

interface Props {
  state: TabNavigationState<ParamListBase>;
  descriptors: {
    [x: string]: {
      render: () =>
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<React.ReactNode>
        | React.ReactPortal
        | null
        | undefined;
    };
  };
}

const TabContent: React.FC<Props> = ({ state, descriptors }) => {
  return (
    <View style={{ flex: 1 }}>
      {state.routes.map((route, i) => {
        return (
          <View key={route.key} style={{ display: i === state.index ? 'flex' : 'none', flex: 1 }}>
            {descriptors[route.key].render()}
          </View>
        );
      })}
    </View>
  );
};

export default TabContent;
