import './src/i18n';
import './src/dates-setup';
import { setupURLPolyfill } from 'react-native-url-polyfill';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import React, { FC, Suspense, useEffect, useRef } from 'react';
import RootStackNavigator from 'src/routes/stacks/RootStackNavigator';
import { ActivityIndicator } from 'react-native-paper';
import { StatusBar } from 'expo-status-bar';
import Toast, {
  BaseToastProps,
  ErrorToast,
  InfoToast,
  SuccessToast
} from 'react-native-toast-message';
import { AuthProvider } from 'src/providers/AuthProvider';
import { QueryClientProvider } from 'react-query';
import styled from 'styled-components/native';
import { useBoolean, useFlushEvents } from 'src/hooks';
import 'moment/min/locales';
import { IS_PROD, IS_WEB, NOTIFICATIONS_SUPPORTED } from 'src/constants';
import * as Linking from 'expo-linking';
import { queryClient as sharedQueryClient } from 'src/utils';
import AppThemeProvider, { ThemeConsumer } from 'src/providers/AppThemeProvider';
import {
  RootStackParamList,
  ROOT_STACK_PATHS
} from 'src/routes/stacks/RootStackNavigator/ParamsList';
import { useTranslation } from 'react-i18next';
import { TourGuideMasterProvider } from 'src/module/TourGuide/TourGuideMaster';
import Sentry, { routingInstrumentation } from 'src/utils/sentry';
import ErrorBoundary from 'src/components/ErrorBoundary';
import AppError from 'src/scenes/AppError';
import { LogBox } from 'react-native';
import { messageHandler } from 'src/utils/remoteNotifications';
import { onMessage } from 'src/utils/firebase';
import NotificationPromptProvider from 'src/providers/NotificationPromptProvider';
import { palette } from 'src/theme';
import {
  AppUserState,
  ImpersonationContext,
  ImpersonationProvider
} from 'src/providers/ImpersonationProvider/ImpersonationProvider';
import DebugFAB from 'src/scenes/Landing/components/DebugFAB';
import { ForcedUpdateProvider } from 'src/providers/ForcedUpdateProvider';
import Constants from 'expo-constants';
import { navigationRef } from 'src/utils/navigationRef';

if (NOTIFICATIONS_SUPPORTED) require('src/providers/NotificationProvider/Notifee');
if (!IS_WEB) setupURLPolyfill();

// Disables the error UI from showing in the simulator
LogBox.ignoreAllLogs();
const prefix = Linking.createURL('');
const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [prefix],
  config: ROOT_STACK_PATHS
};

const INITIAL_LOAD_DELAY = 300;

const App: FC = () => {
  useEffect(() => onMessage(messageHandler), []);

  const { t } = useTranslation();
  const queryClient = useRef(sharedQueryClient).current;
  const { value: isNavReady, toTrue: readyNav } = useBoolean();

  const toastConfig = {
    success: (props: BaseToastProps) => (
      <SuccessToast {...props} text1NumberOfLines={3} text2NumberOfLines={10} />
    ),
    error: (props: BaseToastProps) => (
      <ErrorToast
        {...props}
        style={{ borderLeftColor: palette.RED_WARNING }}
        text1NumberOfLines={3}
        text2NumberOfLines={10}
      />
    ),
    info: (props: BaseToastProps) => (
      <InfoToast {...props} text1NumberOfLines={3} text2NumberOfLines={10} />
    )
  };

  useFlushEvents();

  return (
    <AppThemeProvider>
      <ForcedUpdateProvider>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary Fallback={AppError}>
            <TourGuideMasterProvider>
              <ThemeConsumer>
                {(theme) => (
                  <NavigationContainer
                    ref={navigationRef}
                    theme={{
                      dark: theme.dark,
                      colors: {
                        ...theme.colors,
                        card: theme.colors.surface,
                        border: theme.colors.placeholder
                      }
                    }}
                    linking={linking}
                    onReady={() => {
                      if (!IS_WEB) {
                        routingInstrumentation.registerNavigationContainer(navigationRef);
                      }
                      setTimeout(readyNav, INITIAL_LOAD_DELAY);
                    }}
                    documentTitle={{
                      formatter: (options, route) =>
                        `${String(options?.title ?? route?.name ?? t('login:longAppName'))}`
                    }}
                  >
                    <Suspense fallback={<StyledActivityIndicator size='large' />}>
                      <ImpersonationProvider>
                        <ImpersonationContext.Consumer>
                          {(context) => (
                            <>
                              <NotificationPromptProvider>
                                <AuthProvider isNavReady={isNavReady}>
                                  <StatusBar />
                                  <RootStackNavigator />
                                  <Toast config={toastConfig} />
                                </AuthProvider>
                              </NotificationPromptProvider>
                              {(!IS_PROD ||
                                Constants.expoConfig?.extra?.DEBUG_MENU === 'true' ||
                                context.providerState?.appUserState ===
                                  AppUserState.IMPERSONATE) && <DebugFAB />}
                            </>
                          )}
                        </ImpersonationContext.Consumer>
                      </ImpersonationProvider>
                    </Suspense>
                  </NavigationContainer>
                )}
              </ThemeConsumer>
            </TourGuideMasterProvider>
          </ErrorBoundary>
        </QueryClientProvider>
      </ForcedUpdateProvider>
    </AppThemeProvider>
  );
};

export default IS_WEB ? App : Sentry.wrap(Sentry.withTouchEventBoundary(App));

const StyledActivityIndicator = styled(ActivityIndicator)`
  flex: 1;
`;
