import React, { useState } from 'react';
import { CommonActions, ParamListBase, TabNavigationState } from '@react-navigation/native';
import { Row } from 'src/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTodos } from 'src/hooks';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Size } from 'src/constants';
import styled from 'styled-components/native';
import { getHeaderKey, tabIcon } from '../helper';
import TabItem from './TabItem';
import { Navigators } from '../../navigators';
import { LayoutAnimation } from 'react-native';

interface Props {
  state: TabNavigationState<ParamListBase>;
  navigation: any;
  setBottomTabHeight: React.Dispatch<React.SetStateAction<number>>;
}

const BottomTabNavbar: React.FC<Props> = ({ state, navigation, setBottomTabHeight }) => {
  const { colors } = useAppTheme();
  const { t } = useTranslation('common');

  const [todoAlert, setTodoAlert] = useState(false);
  useTodos({
    isComplete: false,
    onFetchSuccess: (todos) => {
      const dueNow = todos?.some((todo) => moment(todo.dueDate).isBefore() || !todo.dueDate);
      setTodoAlert(dueNow);
    }
  });

  return (
    <Container
      justify='space-between'
      onLayout={({ nativeEvent }) => setBottomTabHeight(nativeEvent.layout.height)}
    >
      {state.routes.map((route, index) => {
        switch (route.name) {
          case Navigators.LOYALTY_STACK:
          case Navigators.REQUEST_STACK:
            return null;
          default:
            return (
              <TabItem
                key={route.key}
                icon={tabIcon(route.name, colors.onSurface, todoAlert)}
                label={t(getHeaderKey(route.name))}
                isSelected={state.index === index}
                onPress={() => {
                  const isFocused = state.index === index;
                  LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                  const event = navigation.emit({
                    type: 'tabPress',
                    target: route.key,
                    canPreventDefault: true,
                    data: {
                      isAlreadyFocused: isFocused
                    }
                  });

                  if (!isFocused && !event.defaultPrevented) {
                    navigation.dispatch({
                      ...CommonActions.navigate(route),
                      target: state.key
                    });
                  }
                }}
              />
            );
        }
      })}
    </Container>
  );
};

export default BottomTabNavbar;

const Container = styled(Row)`
  background-color: ${({ theme }) => theme.colors.surface};
  padding-horizontal: ${Size.S}px;
  padding-top: ${Size.X2_S}px;
  padding-bottom: ${Size.M}px;
`;
