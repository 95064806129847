import React, { useMemo } from 'react';
import { View } from 'react-native';
import { IS_WEB, Size } from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { transparent } from 'src/utils';
import styled from 'styled-components/native';
import TabLabel from './TabLabel';
import { useViewMode } from 'src/hooks';
import { PADDING_TWENTY } from '../helper';

interface Props {
  label: string;
  icon: React.ReactNode;
  onPress: () => void;
  isSelected: boolean;
}

const TabItem: React.FC<Props> = ({ label, icon, onPress, isSelected }) => {
  const { isMobile } = useViewMode();
  const { colors, dark } = useAppTheme();

  const selectedColor = useMemo(() => {
    return dark ? colors.primary : transparent(colors.primary, 0.3);
  }, [colors.primary, dark]);

  return (
    <View
      style={{
        backgroundColor: isSelected && !isMobile ? selectedColor : undefined,
        width: isMobile ? Size.M : undefined
      }}
    >
      <StyledTouchableOpacity onPress={onPress}>
        <ButtonContent>
          {icon}
          {(!isMobile || isSelected) && <TabLabel label={label} />}
        </ButtonContent>
      </StyledTouchableOpacity>
    </View>
  );
};

export default TabItem;

const StyledTouchableOpacity = styled.TouchableOpacity`
  padding-horizontal: ${({ theme }) => (!theme.viewMode.isMobile ? PADDING_TWENTY : 0)}px;
  padding-vertical: ${({ theme }) => (!theme.viewMode.isMobile ? Size.X2_S : 0)}px;
  ${({ theme }) => (theme.viewMode.isMobile && IS_WEB ? 'align-self: center;' : '')}
`;

const ButtonContent = styled.View`
  align-items: center;
  flex-direction: ${({ theme }) => (!theme.viewMode.isMobile ? 'row' : 'undefined')};
  gap: ${({ theme }) => (!theme.viewMode.isMobile ? Size.S : 0)}px;
`;
