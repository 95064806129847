import { isDate } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutRectangle } from 'react-native';
import { Body, Caption, HoverCard, Shimmer } from 'src/components';
import PatientAvatar from 'src/components/PatientAvatar';
import { IconSize, Margin, PATIENT_STATUSES } from 'src/constants';
import { usePatient } from 'src/hooks';
import { Patient } from 'src/interfaces';
import { palette, Weight } from 'src/theme';
import styled from 'styled-components/native';
import PatientGenderIndicator from './PatientGenderIndicator';
import { fontStyles } from 'src/theme/globalStyles';

interface Props {
  numCols: number;
  patientId: Patient['patientId'];
  isFromRequestWizard?: boolean;
  goToPet: () => void;
}

const PatientCard: React.FC<Props> = ({ patientId, numCols, goToPet }) => {
  const [l, setLayout] = useState<LayoutRectangle>();
  const { t } = useTranslation('petList');
  const { data: patient } = usePatient(patientId, { suspense: false });

  const patientStatus = useMemo(() => {
    if (patient?.deceasedDate) {
      return PATIENT_STATUSES.DECEASED;
    }
    return patient?.status.toUpperCase() as PATIENT_STATUSES;
  }, [patient]);

  const age = useMemo(() => {
    if (patient?.birthdate && isDate(new Date(patient.birthdate))) {
      return moment().diff(patient.birthdate, 'years');
    }
    return undefined;
  }, [patient?.birthdate]);

  return (
    <Holder numCols={numCols}>
      <Container onPress={() => goToPet()}>
        <ImageContainer onLayout={(e) => setLayout(e.nativeEvent.layout)}>
          {l?.width && <StyledPatientAvatar patient={patient} size={l?.width ?? IconSize.XXXL} />}
          {patientStatus && patientStatus !== PATIENT_STATUSES.ACTIVE && (
            <StatusContainer>
              <Caption color={palette.AB_BRIGHT_WHITE}>{t(patientStatus)}</Caption>
            </StatusContainer>
          )}
        </ImageContainer>
        <NameContainer>
          <Shimmer height={fontStyles.subheading.lineHeight} width='35%' isLoading={!patient}>
            <Name numberOfLines={1}>{patient?.name ?? '?'}</Name>
          </Shimmer>
          <Shimmer height={fontStyles.subheading.lineHeight} width='15%' isLoading={!patient}>
            <IconContainer>
              {age && <Age>{age}</Age>}
              {patient?.sex && <PatientGenderIndicator sex={patient.sex} size={IconSize.X2S} />}
            </IconContainer>
          </Shimmer>
        </NameContainer>
      </Container>
    </Holder>
  );
};

export default PatientCard;

const Holder = styled.View<{ numCols: number }>`
  width: ${({ numCols }) => 100 / numCols}%;
  aspect-ratio: 1;
`;

const Container = styled(HoverCard)`
  aspect-ratio: 1;
  flex: 1;
  border-radius: ${({ theme }) => theme.roundness}px;
  align-items: center;
  margin: ${Margin.Medium}px;
  cursor: pointer;
  overflow: hidden;
  justify-content: flex-end;
`;

const ImageContainer = styled.View`
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StatusContainer = styled.View`
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: ${Margin.Small}px;
  background-color: ${({ theme }) => theme.colors.backdrop};
  border-top-left-radius: ${({ theme }) => theme.roundness}px;
`;

const NameContainer = styled.View`
  padding: ${Margin.Medium}px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.surface};
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${Margin.Medium}px;
`;

const IconContainer = styled.View`
  flex-direction: row;
  gap: ${Margin.Medium}px;
  align-items: center;
  flex-shrink: 0;
`;

const StyledPatientAvatar = styled(PatientAvatar)`
  border-top-left-radius: ${({ theme }) => theme.roundness}px;
  border-top-right-radius: ${({ theme }) => theme.roundness}px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const Age = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
`;

const Name = styled(Body)`
  color: ${({ theme }) => theme.colors.onSurface};
  font-weight: ${Weight.MEDIUM};
`;
