import { useQuery } from 'react-query';
import { getPatientReminders, QueryKeys } from 'src/api';

const usePatientReminders = (sourceId: number, patientId: string) => {
  return useQuery({
    queryKey: [QueryKeys.PATIENT_REMINDERS, sourceId, patientId],
    queryFn: async () => await getPatientReminders(sourceId, patientId)
  });
};

export default usePatientReminders;
