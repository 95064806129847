import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import { Body, HoverButton } from 'src/components';
import { Margin, PATIENT_STATUSES } from 'src/constants';
import { palette, Weight } from 'src/theme';
import { useTranslation } from 'react-i18next';
import { fontStyles } from 'src/theme/globalStyles';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { PAW_PATH_SIZE } from 'src/components/FloatingPawPath';
import { transparent } from 'src/utils';
import { X } from 'react-native-feather';
import { SIDEBAR_WIDTH } from 'src/module/FormKit/components/constants';

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  selectedStatus: PATIENT_STATUSES | undefined;
  setSelectedStatus: (patientStatus: PATIENT_STATUSES | undefined) => void;
}

const SidebarPatientTypeSelector: React.FC<Props> = ({
  isOpen,
  toggleOpen,
  setSelectedStatus,
  selectedStatus
}) => {
  const { t } = useTranslation('petList');
  const { viewMode, colors } = useAppTheme();

  const statusOptions = useMemo(() => [undefined, ...Object.keys(PATIENT_STATUSES)], []);

  if (!isOpen) {
    return null;
  }

  return (
    <StyledFlatList
      ListHeaderComponent={
        <HeaderContainer>
          <BoldBody>{t('viewing')}</BoldBody>
          {!viewMode.isWeb && <CloseButton onPress={toggleOpen} />}
        </HeaderContainer>
      }
      ListHeaderComponentStyle={{ marginBottom: Margin.ExtraLarge }}
      data={statusOptions}
      contentContainerStyle={{
        gap: Margin.Small,
        padding: Margin.ExtraLarge,
        paddingBottom: PAW_PATH_SIZE
      }}
      renderItem={({ item: p }) => {
        return (
          <StyledButton
            key={String(p)}
            onPress={() => {
              setSelectedStatus(
                p && Object.keys(PATIENT_STATUSES).includes(p as PATIENT_STATUSES)
                  ? (p as PATIENT_STATUSES)
                  : undefined
              );
              toggleOpen();
            }}
            buttonColor={
              p === selectedStatus ? transparent(colors.primary, 0.3) : palette.SELECTOR_GREY
            }
            selected={selectedStatus === p}
          >
            <BoldBody>{t(String(p ?? 'all'), { count: 0 })}</BoldBody>
          </StyledButton>
        );
      }}
    />
  );
};

export default SidebarPatientTypeSelector;

const StyledFlatList = styled.FlatList`
  background-color: ${palette.SELECTOR_GREY};
  ${({ theme }) => theme.viewMode.isWeb && `max-width: ${SIDEBAR_WIDTH}px;`}
`;

const CloseButton = styled(HoverButton).attrs(({ theme }) => ({
  icon: X,
  buttonColor: theme.colors.text
}))``;

const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between
  align-items: center
`;

const BoldBody = styled(Body)`
  font-weight; ${Weight.BLACK};
  color: ${({ theme }) => theme.colors.text}
`;

const StyledButton = styled(HoverButton).attrs(({ theme }) => ({
  mode: 'contained',
  labelStyle: {
    ...fontStyles.body,
    fontWeight: Weight.BOLD,
    color: theme.colors.text,
    marginVertical: Margin.Medium
  },
  uppercase: false
}))<{ selected: boolean }>`
  justify-content: flex-start;
  padding-horizontal: ${Margin.Large}px;
  padding-vertical: ${Margin.Small}px;
  gap: ${Margin.Small}px;
`;
