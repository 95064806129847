import { PathConfig } from 'src/routes/types';
import { Screens } from '../screens';
import Patient from 'src/interfaces/api/Patient';

export type PatientStackParamsList = {
  [Screens.PATIENT_LIST]?: undefined;
  [Screens.PATIENT_PROFILE]: {
    id: Patient['patientId'];
  };
};

export const PET_STACK_PATHS: PathConfig<PatientStackParamsList> = {
  initialRouteName: Screens.PATIENT_LIST,
  path: 'pet',
  screens: {
    [Screens.PATIENT_LIST]: '',
    [Screens.PATIENT_PROFILE]: 'patient-profile'
  }
};

export default PatientStackParamsList;
