export enum Navigators {
  TAB_STACK = 'Tabs',
  HOME_STACK = 'Home',
  MAIN_STACK = 'Main',
  REQUEST_STACK = 'Request',
  LOYALTY_STACK = 'Loyalty',
  APPOINTMENTS_STACK = 'Appointment',
  PET_STACK = 'Pets',
  TODO_STACK = 'Todo',
  PATIENT_UPDATE_STACK_NAVIGATOR = 'PATIENT_UPDATE_STACK_NAVIGATOR'
}
