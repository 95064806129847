import {
  useNavigation,
  NavigationProp,
  ParamListBase,
  TabNavigationState
} from '@react-navigation/native';
import React, { useMemo } from 'react';
import { useUser } from 'src/providers/ClientProvider';
import { Screens } from 'src/routes/stacks/screens';
import { Body, HoverButton } from 'src/components';
import { IconSize, Margin, Size } from 'src/constants';
import styled from 'styled-components/native';
import { useAbility } from 'src/hooks';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Navigators } from 'src/routes/stacks/navigators';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Weight } from 'src/theme';
import { Inbox } from 'react-native-feather';
import { Trophy } from 'src/components/kit/ABIcons';
import { containers } from 'src/theme/globalStyles';
import HeaderIcon from './HeaderIcon';
import { getHeaderKey } from '../helper';
import { useTranslation } from 'react-i18next';

const HEADER_Z_INDEX = 1;

interface Props {
  state: TabNavigationState<ParamListBase>;
}
const MobileHeader: React.FC<Props> = ({ state }) => {
  const { navigate } = useNavigation<NavigationProp<ParamListBase>>();
  const { t } = useTranslation('common');
  const { user } = useUser();
  const { userCanViewLoyalty } = useAbility();
  const { colors } = useAppTheme();
  const { top } = useSafeAreaInsets();

  const activeRoute = useMemo(() => state.routeNames[state.index], [state.index, state.routeNames]);

  return (
    <>
      <Container style={[containers.shadow, { paddingTop: top }]}>
        <TitleContainer>
          <HeaderIcon routeName={activeRoute} size={IconSize.M} color={colors.onSurface} />
          <Body fontWeight={Weight.MEDIUM}>
            {t(
              activeRoute === Navigators.HOME_STACK
                ? 'routeTitles.name'
                : getHeaderKey(activeRoute)!,
              user
            )}
          </Body>
        </TitleContainer>
        {userCanViewLoyalty && (
          <HoverButton
            onPress={() => navigate(Navigators.LOYALTY_STACK, { screen: Screens.LOYALTY_REWARDS })}
          >
            <Trophy height={IconSize.M} width={IconSize.M} color={colors.onSurface} />
          </HoverButton>
        )}
        <HoverButton
          onPress={() => navigate(Navigators.REQUEST_STACK, { screen: Screens.REQUEST })}
        >
          <Inbox height={IconSize.M} width={IconSize.M} color={colors.onSurface} />
        </HoverButton>
      </Container>
    </>
  );
};

export default MobileHeader;

const Container = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface};
  justify-content: space-between;
  padding-bottom: ${Size.X2_S}px;
  padding-right: ${Size.X2_S}px;
  border-bottom-left-radius: ${Size.M}px;
  border-bottom-right-radius: ${Size.M}px;
  z-index: ${HEADER_Z_INDEX};
  padding-left: ${Size.S};
`;

const TitleContainer = styled.View`
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  gap: ${Margin.Medium}px;
`;
