import React from 'react';
import { Text } from 'react-native-paper';
import { Size } from 'src/constants';
import styled from 'styled-components/native';

interface Props {
  label: string;
}

const TabLabel: React.FC<Props> = ({ label }) => {
  return <StyledTabLabel numberOfLines={1}>{label}</StyledTabLabel>;
};

export default TabLabel;

const StyledTabLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.onSurface};
  width: ${({ theme }) => (theme.viewMode.isMobile ? `${Size.X5_L}px` : undefined)};
  font-size: ${({ theme }) => (theme.viewMode.isMobile ? Size.XS : Size.S)}px;
  text-align: center;
  overflow: visible;
`;
