import React, { useEffect } from 'react';
import { HoverButton } from 'src/components';
import { FormState } from '../model';
import styled from 'styled-components/native';
import { Margin } from 'src/constants';
import { Check, ChevronLeft, Save } from 'react-native-feather';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { useNavigation } from '@react-navigation/native';

interface Props {
  state: FormState;
  formGoBack: () => void;
  formAdvance: () => void;
  dirty: boolean;
  hasContactMethod: boolean;
  name?: string;
  handleSubmit: () => void;
}
const useNavControl = ({
  state,
  formGoBack,
  formAdvance,
  handleSubmit,
  dirty,
  hasContactMethod,
  name
}: Props) => {
  const { t } = useTranslation('informationUpdate');
  const { colors, viewMode } = useAppTheme();
  const { setOptions } = useNavigation();

  useEffect(() => {
    if (state === FormState.EDIT_INFO) {
      setOptions({
        headerLeft: () => <BackButton icon={ChevronLeft} onPress={formGoBack} />,
        title: t('editing', { name }),
        headerRight: () => (
          <ContinueButton disabled={!dirty} icon={Save} onPress={formAdvance}>
            {!viewMode.isMobile && t('common:saveChanges')}
          </ContinueButton>
        )
      });
    } else {
      setOptions({
        headerLeft: () => <BackButton icon={ChevronLeft} onPress={formGoBack} />,
        title: t(`confirmYourChanges`),
        headerRight: () => (
          <ContinueButton disabled={!hasContactMethod} icon={Check} onPress={handleSubmit}>
            {!viewMode.isMobile && t('common:confirm')}
          </ContinueButton>
        )
      });
    }
  }, [
    colors.primary,
    colors.text,
    dirty,
    formAdvance,
    formGoBack,
    handleSubmit,
    hasContactMethod,
    name,
    setOptions,
    state,
    t,
    viewMode.isMobile
  ]);
};

export default useNavControl;

const BackButton = styled(HoverButton).attrs(({ theme }) => ({
  buttonColor: theme.colors.text,
  uppercase: false
}))`
  padding-horizontal: ${Margin.Large}px;
`;

const ContinueButton = styled(HoverButton).attrs(({ theme }) => ({
  buttonColor: theme.viewMode.isMobile ? theme.colors.text : theme.colors.primary,
  uppercase: false,
  mode: theme.viewMode.isMobile ? 'text' : 'contained'
}))`
  flex-direction: row-reverse;
  padding-horizontal: ${Margin.Large}px;
  ${({ theme }) => (theme.viewMode.isMobile ? '' : `margin-right: ${Margin.Medium}px;`)}
`;
