import React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

// Labeled "single-name" in AB icons. Changed to "person" for context
const Person: React.FC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      stroke={props.color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M17.185 18.657a7.31 7.31 0 0 0-10.37 0m1.247-8.345a3.937 3.937 0 1 0 7.875 0 3.937 3.937 0 0 0-7.874 0ZM3.563 12a8.437 8.437 0 1 0 16.875 0 8.437 8.437 0 0 0-16.875 0Z'
    />
  </Svg>
);
export default Person;
