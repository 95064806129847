import { useQuery } from 'react-query';
import { getPractices, QueryKeys } from 'src/api';

const usePractice = () => {
  return useQuery({
    queryKey: [QueryKeys.PRACTICES],
    queryFn: getPractices
  });
};

export default usePractice;
