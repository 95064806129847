import React, { useMemo } from 'react';
import { ActivityIndicator } from 'react-native-paper';
import { IconSize, Margin, PATIENT_STATUSES, Size } from 'src/constants';
import PatientCard from './PatientCard';
import { FlatList, RefreshControl } from 'react-native';
import usePaginatedPatients from 'src/hooks/react-query/usePaginatedPatients';
import styled from 'styled-components/native';
import PatientIconMessage from './PatientIconMessage';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Screens } from 'src/routes/stacks/screens';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import useFetchNextPage from 'src/hooks/useFetchNextPage';
import { Filter } from 'src/components/Icons';
import { HoverButton, Body } from 'src/components';
import { Weight } from 'src/theme';
import PatientStackParamsList from 'src/routes/stacks/PatientStackNavigator/ParamsList';
import { PAW_PATH_SIZE } from 'src/components/FloatingPawPath';
import { Patient } from 'src/interfaces';
import HelpCenterSection from 'src/components/HelpCenterSection';

interface Props {
  patientStatus?: PATIENT_STATUSES;
  petNavOverride?: (id: Patient['patientId']) => void;
  toggleOpen: () => void;
  selectedStatus: PATIENT_STATUSES | undefined;
  filterIsOpen: boolean;
}

const PAGE_LENGTH = 5;

const PatientListContent: React.FC<Props> = ({
  patientStatus,
  petNavOverride,
  toggleOpen,
  filterIsOpen,
  selectedStatus
}) => {
  const { t } = useTranslation('petList');
  const { colors, viewMode } = useAppTheme();

  const { navigate } = useNavigation<NavigationProp<PatientStackParamsList>>();
  const goToPet = (patientId: string) => {
    if (petNavOverride) {
      petNavOverride(patientId);
    } else {
      navigate(Screens.PATIENT_PROFILE, { id: patientId });
    }
  };

  const numCols = useMemo(() => (viewMode.isMobile ? 2 : 3), [viewMode.isMobile]);

  const {
    data: patientList = [],
    isFetched,
    isRefetching,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = usePaginatedPatients({
    status: patientStatus,
    suspense: false,
    length: numCols * PAGE_LENGTH
  });

  const { setToggle } = useFetchNextPage(hasNextPage, isFetched, fetchNextPage);

  return (
    <FlatList
      extraData={numCols}
      style={[!viewMode.isWeb && filterIsOpen && { display: 'none' }]}
      numColumns={numCols}
      data={patientList}
      refreshing={isRefetching}
      refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refetch} />}
      ListHeaderComponent={
        <>
          {!viewMode.isWeb && (
            <MobileRow>
              <SemiBoldBody>{t('viewingType', { type: t(selectedStatus ?? 'all') })}</SemiBoldBody>
              <FilterButton onPress={toggleOpen}>{t('filters')}</FilterButton>
            </MobileRow>
          )}
        </>
      }
      ListEmptyComponent={
        isFetched ? (
          <PatientIconMessage message={t('noPetsFound')} />
        ) : (
          <ActivityIndicator size='large' color={colors.primary} style={{ flex: 1 }} />
        )
      }
      contentContainerStyle={[
        {
          maxWidth: viewMode.isWeb ? viewMode.maxContentWidth : '100%',
          padding: viewMode.isMobile ? Margin.Medium : Margin.ExtraLarge,
          paddingBottom: PAW_PATH_SIZE
        }
      ]}
      renderItem={({ item, index }) => (
        <PatientCard
          numCols={numCols}
          key={item.patientId}
          patientId={item.patientId}
          goToPet={() => goToPet(item.patientId)}
        />
      )}
      onEndReached={() => setToggle((prev) => !prev)}
      ListFooterComponent={
        <>
          {isFetchingNextPage && <LoadingNextIndicator />}
          {!hasNextPage && !isFetchingNextPage && !!patientList.length && (
            <PatientIconMessage message={t('noMorePets')} />
          )}
          {viewMode.isMobile && <HelpCenterSection />}
        </>
      }
    />
  );
};

export default PatientListContent;

const LoadingNextIndicator = styled(ActivityIndicator)`
  margin: ${Size.M}px;
  margin-bottom: ${PAW_PATH_SIZE}px;
`;

const FilterButton = styled(HoverButton).attrs(({ theme }) => ({
  icon: Filter,
  mode: 'outlined',
  buttonColor: theme.colors.text,
  uppercase: false
}))`
  padding: ${Margin.Medium}px ${Margin.Large}px;
  border-radius: ${IconSize.M};
  gap: ${Margin.Small}px;
`;

const MobileRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${Margin.Medium}px ${Margin.Large}px;
`;

const SemiBoldBody = styled(Body)`
  font-weight: ${Weight.MEDIUM};
  color: ${({ theme }) => theme.colors.primary};
`;
