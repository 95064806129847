export enum PATIENT_STATUSES {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DECEASED = 'DECEASED'
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female'
}
