import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PatientAvatar from 'src/components/PatientAvatar';
import TempImageAvatar from 'src/scenes/PatientProfileUpdate/components/TempImageAvatar';
import {
  IMG_ASPECT_RATIO_MOBILE,
  IMG_ASPECT_RATIO_STANDARD,
  IS_WEB,
  PATIENT_STATUSES,
  Size
} from 'src/constants';
import { Patient } from 'src/interfaces';
import styled from 'styled-components/native';
import { FormState, ReducerState } from '../model';
import useUploadPatientPhoto from 'src/hooks/react-query/useUploadPatientPhoto';
import _ from 'lodash';
import { Camera, Trash2, Image as FeatherImage } from 'react-native-feather';
import { palette } from 'src/theme';
import { Caption } from 'src/components';
import { LayoutRectangle, Platform, View } from 'react-native';
import { GuideElement, STEPS } from 'src/scenes/PatientList/tour';
import { StyledFAB } from '../style';
import { FormStateHook } from '../hooks/useFormState';
import { SIDEBAR_WIDTH } from 'src/module/FormKit/components/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';

interface Props {
  state: ReducerState;
  patient: Patient;
  setFieldValue: FormStateHook['setFieldValue'];
  newPatientImageUri: string | null;
  patientIsMutating: boolean;
}

const PatientProfileAvatar: React.FC<Props> = ({
  state,
  patient,
  setFieldValue,
  newPatientImageUri,
  patientIsMutating
}) => {
  const { viewMode } = useAppTheme();
  const { t } = useTranslation();

  const { preparePhotoUpload, isMutating } = useUploadPatientPhoto(patient);

  const onPressCameraOrUpload = useMemo(
    () =>
      _.debounce(async (isCamera: boolean) => {
        if (!patient) return;
        const uri = await preparePhotoUpload(isCamera);
        if (uri) void setFieldValue('newPatientImageUri', uri);
      }),
    [patient, preparePhotoUpload, setFieldValue]
  );

  const onPressDeletePhoto = useMemo(
    () =>
      _.debounce(() => {
        void setFieldValue('newPatientImageUri', '');
      }),
    [setFieldValue]
  );

  const patientStatus = useMemo(() => {
    if (patient?.deceasedDate) {
      return PATIENT_STATUSES.DECEASED;
    }
    return patient?.status.toUpperCase() as PATIENT_STATUSES;
  }, [patient]);

  const avatarAspectRatio = viewMode.isWeb ? IMG_ASPECT_RATIO_STANDARD : IMG_ASPECT_RATIO_MOBILE;

  const [layout, setLayout] = useState<LayoutRectangle>();
  const width = layout?.width ?? (viewMode.isWeb ? SIDEBAR_WIDTH : viewMode.windowWidth);

  return (
    <Container onLayout={({ nativeEvent }) => setLayout(nativeEvent.layout)}>
      <GuideElement body={t('patientProfile:tour.photos')} id={STEPS.PHOTOS}>
        <View style={{ width }}>
          {newPatientImageUri === null && (
            <StyledPatientAvatar patient={patient} size={width} aspectRatio={avatarAspectRatio} />
          )}
          {newPatientImageUri !== null && (
            <TempImageAvatar
              uri={newPatientImageUri}
              aspectRatio={avatarAspectRatio}
              width={width}
              species={patient.species}
              patientIsMutating={patientIsMutating}
            />
          )}
          {state.state === FormState.EDIT_INFO && !isMutating && (
            <EditFabContainer>
              {!IS_WEB && (
                <StyledFAB
                  icon={({ color, size }) => <Camera color={color} width={size} height={size} />}
                  onPress={() => onPressCameraOrUpload(true)}
                />
              )}
              <StyledFAB
                icon={({ color, size }) => (
                  <FeatherImage color={color} width={size} height={size} />
                )}
                onPress={() => onPressCameraOrUpload(false)}
              />
              <StyledFAB
                icon={({ color, size }) => <Trash2 color={color} width={size} height={size} />}
                onPress={onPressDeletePhoto}
              />
            </EditFabContainer>
          )}
          {patientStatus !== PATIENT_STATUSES.ACTIVE && (
            <StatusContainer>
              <Caption color={palette.AB_BRIGHT_WHITE}>{t(`petList:${patientStatus}`)}</Caption>
            </StatusContainer>
          )}
        </View>
      </GuideElement>
    </Container>
  );
};

export default PatientProfileAvatar;

const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.background};
`;

const StatusContainer = styled.View`
  position: absolute;
  bottom: ${Platform.OS === 'web' ? 0 : Size.S}px;
  left: 0px;
  padding: ${Size.X3_S}px;
  background-color: ${({ theme }) => theme.colors.backdrop};
  border-top-right-radius: ${({ theme }) => theme.roundness}px;
`;

const EditFabContainer = styled.View`
  position: absolute;
  gap: ${Size.S}px;
  right: ${({ theme }) => (theme.viewMode.isMobile ? Size.XS : Size.S)}px;
  bottom: ${!IS_WEB ? Size.L : Size.S}px;
`;

const StyledPatientAvatar = styled(PatientAvatar)`
  border-radius: 0px;
`;
