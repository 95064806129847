import { Appointment, Patient, Reward } from 'src/interfaces';
import LoyaltyTransaction from 'src/interfaces/api/LoyaltyTransaction';
import { PathConfig } from 'src/routes/types';
import { Navigators } from '../navigators';
import { Screens } from '../screens';
import TabStackParamsList, { TAB_STACK_PATHS } from '../TabStackNavigator/ParamsList';
import PatientUpdateStackParamsList, {
  PET_UPDATE_STACK_PATHS
} from '../PatientUpdateStackNavigator/ParamsList';

export type MainStackParamsList = {
  [Navigators.TAB_STACK]: TabStackParamsList;
  [Screens.LOYALTY_REWARD]: {
    id: Reward['id'];
  };
  [Screens.TRANSACTION_DETAILS]: {
    id?: LoyaltyTransaction['id'];
    isExpiredItem?: boolean;
    expireAmount?: number;
    expireDate?: Date;
  };
  [Screens.PATIENT_ID]?: {
    id: Patient['patientId'];
  };
  [Screens.SELECT_PRACTICE]?: undefined;
  [Screens.CONTACT_US]?: undefined;
  [Screens.AFTER_HOURS]?: undefined;
  [Screens.ADD_TO_CALENDAR]?: {
    id?: Appointment['appointmentId'];
    species?: string;
  };
  [Screens.BIOMETRIC_LOGIN_OPT_IN]?: undefined;
  [Screens.PROFILE_INFORMATION_UPDATE]?: undefined;
  [Screens.REQUESTS_PRODUCT]?: undefined;
  [Navigators.PATIENT_UPDATE_STACK_NAVIGATOR]?: PatientUpdateStackParamsList;
};

export const MAIN_STACK_PATHS: PathConfig<MainStackParamsList> = {
  initialRouteName: Navigators.TAB_STACK,
  screens: {
    [Navigators.TAB_STACK]: TAB_STACK_PATHS,
    [Screens.LOYALTY_REWARD]: 'loyalty-reward',
    [Screens.TRANSACTION_DETAILS]: 'transaction-details',
    [Screens.PATIENT_ID]: 'pet-id',
    [Screens.SELECT_PRACTICE]: 'practices',
    [Screens.CONTACT_US]: 'contact-us',
    [Screens.AFTER_HOURS]: 'emergency',
    [Screens.ADD_TO_CALENDAR]: 'add-to-calendar',
    [Screens.BIOMETRIC_LOGIN_OPT_IN]: 'biometric-log-in-opt-in',
    [Screens.PROFILE_INFORMATION_UPDATE]: 'profile-information-update',
    [Screens.REQUESTS_PRODUCT]: 'product-request',
    [Navigators.PATIENT_UPDATE_STACK_NAVIGATOR]: PET_UPDATE_STACK_PATHS
  }
};

export default MainStackParamsList;
