import { ViewStyle, StyleSheet } from 'react-native';

const extractStyles = (
  style: ViewStyle | undefined
): {
  innerStyle: ViewStyle;
  outerStyle: ViewStyle;
} => {
  if (!style) {
    return {
      innerStyle: {},
      outerStyle: {}
    };
  }

  const {
    backgroundColor,

    padding,
    paddingBottom,
    paddingEnd,
    paddingLeft,
    paddingRight,
    paddingHorizontal,
    paddingStart,
    paddingTop,
    paddingVertical,

    borderRadius,
    borderTopEndRadius,
    borderTopLeftRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    borderBottomEndRadius,
    borderBottomStartRadius,
    borderTopRightRadius,
    borderTopStartRadius,

    borderBottomColor,
    borderBottomWidth,
    borderColor,
    borderEndColor,
    borderEndWidth,
    borderLeftColor,
    borderLeftWidth,
    borderRightColor,
    borderRightWidth,
    borderStartColor,
    borderStartWidth,
    borderStyle,
    borderTopColor,
    borderTopWidth,
    borderWidth,

    flexDirection,
    flexWrap,
    justifyContent,
    alignContent,
    alignItems,

    gap,
    rowGap,
    columnGap,

    elevation,
    shadowColor,
    shadowOffset,
    shadowOpacity,
    shadowRadius,

    aspectRatio,
    height,
    width,
    minHeight,
    minWidth,
    maxHeight,
    maxWidth,

    ...rest
  } = style;

  const borderProps = {
    borderBottomColor,
    borderBottomWidth,
    borderColor,
    borderEndColor,
    borderEndWidth,
    borderLeftColor,
    borderLeftWidth,
    borderRightColor,
    borderRightWidth,
    borderStartColor,
    borderStartWidth,
    borderStyle,
    borderTopColor,
    borderTopWidth,
    borderWidth
  };

  const borderRadiusProps = {
    borderRadius,
    borderTopEndRadius,
    borderTopLeftRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    borderBottomEndRadius,
    borderBottomStartRadius,
    borderTopRightRadius,
    borderTopStartRadius
  };

  const paddingProps = {
    padding,
    paddingBottom,
    paddingEnd,
    paddingLeft,
    paddingRight,
    paddingHorizontal,
    paddingStart,
    paddingTop,
    paddingVertical
  };

  const contentAlignmentProps = {
    flexDirection,
    flexWrap,
    justifyContent,
    alignContent,
    alignItems,
    gap,
    rowGap,
    columnGap
  };

  const shadowProps = {
    elevation,
    shadowColor,
    shadowOffset,
    shadowOpacity,
    shadowRadius
  };

  const backgroundProps = {
    backgroundColor
  };

  const sizingProps = {
    aspectRatio,
    height,
    width,
    minHeight,
    minWidth,
    maxHeight,
    maxWidth
  };

  const innerStyle = StyleSheet.flatten([
    backgroundProps,
    borderProps,
    borderRadiusProps,
    paddingProps,
    contentAlignmentProps,
    shadowProps,
    sizingProps
  ]);

  const outerStyle = StyleSheet.flatten([rest, borderRadiusProps]);

  return {
    innerStyle: filterUndefined(innerStyle),
    outerStyle: filterUndefined(outerStyle)
  };
};

export default extractStyles;

const filterUndefined = (obj: Record<string, any>) =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined));
